.footer {
/*  display: flex;*/
  //flex-wrap: nowrap;
  width: 100%;
  min-width: 1450px;
  height: 280px;
  background: #2a2a2a;
}

.mobile-footer {
  /*  display: flex;*/
  //flex-wrap: nowrap;
  width: 100%;
  height: 666px;
  background: #2a2a2a;
}

.footer-wrapper {
  width: 1400px;
  margin: 0 auto;
}

.mobile-footer-wrapper {
  width: 100%;
  margin: 0 auto;
}

.footer-left {
  flex: 1;
  float: left;
  //width: 20%;
  margin: 20px 0 auto 10px;
  padding-left: 150px;
  //background: #8c8989;
}

.mobile-footer-left {
  flex: 1;
  float: left;
  width: 30%;
  margin: 20px auto 10px;
  padding-left: 20px;
  //background: #8c8989;
}

.footer-logo {
  width: 150px;
  //height: 100px;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

.mobile-footer-logo {
  width: 250px;
  margin-left: 20px;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

.mobile-we-code {
  width: 200px;
  margin-top: 16px;
  margin-left: 60px;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

.footer-left-text {
  margin: 25px auto;
  line-height: 16px;
  p {
    margin: 10px auto;
    font-size: 16px;
    color: #ffffff;
  }
  p span {
    font-size: 16px;
    color: #12c1ba;
  }
}

.mobile-footer-left-text {
  text-align: left;
  width: 82%;
  margin: 25px auto;
  line-height: 25px;
  p {
    margin: 15px auto;
    font-size: 25px;
    color: #ffffff;
  }
  p span {
    font-size: 25px;
    color: #12c1ba;
  }
}

.footer-right {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: flex-start;
  width: 50%;
  margin: auto;
  padding-left: 50px;
  //float: right;
  //width: 70%;
  //margin: 2vh 0 2vh 0;
  text-align: center;
  //background: #2d5364;
  dl {
    //display: inline-block;
    margin: 0 40px;
    //vertical-align: top;
    //text-align: center;
  }
  .footer-right-title {
    line-height: 66px;
  }
  .footer-right-title h3 {
    font-family: "微软雅黑";
    font-size: 18px;
    font-weight: bold;
    color: #fff;
  }
  .footer-right-text {
    display: flex;
    flex-direction: column;
    line-height: 36px;
    a {
      font-family: '思源黑体';
      font-size: 14px;
      color: white;
      cursor: pointer;
    }
  }
}

.mobile-footer-right {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: flex-start;
  width: 68%;
  margin: auto;
  padding-left: 5%;
  padding-top: 20px;
  text-align: left;
  dl {
    width: 25%;
    margin: 0 10px;
  }
  .mobile-footer-right-title {
    line-height: 66px;
  }
  .mobile-footer-right-title h3 {
    font-family: "微软雅黑";
    font-size: 28px;
    font-weight: bold;
    color: #fff;
  }
  .mobile-footer-right-text {
    display: flex;
    flex-direction: column;
    line-height: 50px;
    a {
      font-family: '思源黑体';
      font-size: 22px;
      color: white;
      cursor: pointer;
    }
  }
}

.footer-copyright {
  position: relative;
  margin: 4px auto 0;
  width: 1400px;
  height: 44px;
  text-align: center;
  line-height: 44px;
  border-top: 2px solid #b6cbe2;
  color: white;
  font-size: 1vh;
}

.footer-copyright .a {
  color: white;
}

.mobile-footer-copyright {
  position: relative;
  margin: 60px auto;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-top: 2px solid #b6cbe2;
  padding-top: 10px;
  color: white;
  font-size: 23px;
}

.mobile-footer-copyright .a {
  color: white;
}