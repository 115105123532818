@import '~antd/dist/antd.css';

.putao-out-pc {
    width: 100%;
    min-height: 500px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    /*align-items: center;*/
}

.putao-out-m {
    width: 100%;
    min-height: 500px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    /*align-items: center;*/
}

.putao-out-pc-pt {
    width: 40%;
    max-width: 900px;
    min-width: 600px;
    min-height: 500px;
    background: rgb(255, 247, 238);
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.2);
}

.putao-out-pc-m {
    width: 100%;
    min-height: 100vh;
    background: rgb(255, 247, 238);
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.2);
}

.putao-out-pc-header {
    height: 70px;
    width: 100%;
    background: rgb(255,133,42);
    display: flex;
    align-items: center;
}

.putao-out-m-header {
    height: 140px;
    width: 100%;
    background: rgb(255,133,42);
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 999;
}

.putao-out-m-header-des {
    display: flex;
    align-items: center;
}

.putao-out-bt-su {
    height: 200px;
    width: 100%;
    background-color: #ffffff;
    position: fixed;
    bottom: 0;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.putao-out-bt-su-price {
    color: red;
    font-weight: bold;
    font-size: 40px;
}

.putao-out-bt-su-next {
    width: 300px;
    height: 90px;
    /*background-color: #08e558;*/
    border-radius: 25px;
    font-size: 30px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 40px;
    margin-left: 50px;
}

.putao-out-bt-su-submit {
    width: calc(100% - 80px);
    height: 90px;
    /*background-color: #08e558;*/
    border-radius: 25px;
    font-size: 30px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 40px 100px;
}

.putao-out-pc-logo {
    height: 40px;
    width: auto;
    margin-left: 20px;
    /*image-rendering: -moz-crisp-edges;*/
    /*image-rendering: -o-crisp-edges;*/
    /*image-rendering: -webkit-optimize-contrast;*/
    /*image-rendering: crisp-edges;*/
    /*-ms-interpolation-mode: nearest-neighbor;*/
}

.putao-out-m-logo {
    height: 80px;
    width: auto;
    margin-left: 40px;
    /*image-rendering: -moz-crisp-edges;*/
    /*image-rendering: -o-crisp-edges;*/
    /*image-rendering: -webkit-optimize-contrast;*/
    /*image-rendering: crisp-edges;*/
    /*-ms-interpolation-mode: nearest-neighbor;*/
}

.putao-out-pc-title {
    font-size: 18px;
    color: white;
    letter-spacing: 2px;
    margin-left: 10px;
    font-weight: bold;
}

.putao-out-m-title {
    font-size: 40px;
    color: white;
    letter-spacing: 2px;
    margin-left: 40px;
    font-weight: bold;
}

.putao-out-pc-item {
    width: 100%;
    height: 200px;
    background: rgb(255, 255, 255);
    margin-top: 5px;
    display: flex;
}

.putao-out-m-item {
    width: 100%;
    height: 300px;
    background: rgb(255, 255, 255);
    margin-top: 16px;
    display: flex;
}

.putao-out-pc-item-img {
    width: 200px;
    height: 200px;
    /*background: grey;*/
}

.putao-out-m-item-img {
    width: 300px;
    height: 300px;
    /*background: grey;*/
}

.putao-out-pc-item-des {
    width: calc(100% - 200px);
    height: 200px;
    background: white;
    position: relative;
}

.putao-out-m-item-des {
    width: calc(100% - 300px);
    height: 300px;
    background: white;
    position: relative;
}

.putao-out-pc-item-des-name {
    font-size: 20px;
    font-weight: bold;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
}

.putao-out-m-item-des-name {
    font-size: 30px;
    font-weight: bold;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
}

.putao-out-pc-item-des-des {
    font-size: 16px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 4px;
    color: grey;
}

.putao-out-m-item-des-des {
    font-size: 26px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 4px;
    color: grey;
}

.putao-out-pc-item-des-bt {
    height: 70px;
    width: 100%;
    /*background-color: #ffe6e6;*/
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.putao-out-m-item-des-bt {
    height: 120px;
    width: 100%;
    /*background-color: #ffe6e6;*/
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.putao-out-pc-item-des-bt-comm {
    color: grey;
    margin-right: 20px;
    font-size: 16px;
}

.putao-out-pc-item-des-bt-act {
    color: red;
    margin-right: 20px;
    font-size: 20px;
    font-weight: bold;
}

.putao-out-m-item-des-bt-act {
    color: red;
    margin-right: 20px;
    font-size: 30px;
    font-weight: bold;
}

.putao-out-m-item-des-bt-clk {
    height: 50px;
    width: 50px;
    background-color: #bbbbbb;
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
}

.putao-out-m-item-des-bt-num {
    height: 50px;
    width: 50px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
}

.putao-out-pc-summary {
    width: 100%;
    height: 160px;
    background: rgb(255, 255, 255);
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.putao-out-pc-summary-total {
    color: #ff4e00;
    margin-right: 20px;
    margin-left: 40px;
    font-size: 30px;
    font-weight: bold;
}

.putao-out-pc-summary-pay {
    height: 50px;
    width: 230px;
    border: 0;
    color: white;
    background-color: #25bb35;
    font-size: 20px;
    margin-right: 40px;
}

.putao-order-m-out {
    height: 160px;
    width: 100%;
    background: #f3f3f3;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-top: 10px;
}

.putao-order-m-stat {
    display: flex;
    justify-content: flex-end;
    margin-right: 40px;
    font-size: 34px;
}

.putao-order-m-name {
    margin-left: 40px;
    font-size: 34px;
    font-weight: bold;
}

.putao-order-m-stat-price {
    margin-right: 40px;
}

.putao-order-m-stat-num {
}

.putao-out-bt-su-price-sum {
    color: red;
    font-weight: bold;
    font-size: 40px;
    display: flex;
    margin-top: 40px;
    justify-content: flex-end;
    margin-right: 40px;
}

.putao-out-bt-su-express {
    font-size: 30px;
    margin-left: 40px;
    margin-top: 60px;
}

.putao-out-bt-su-input {
    margin-top: 20px;
    width: calc(100% - 80px);
    margin-left: 40px;
    height: 400px;
    font-size: 34px;
    border: 2px solid #dbdbdb;
    outline-color:#dbdbdb;
    border-radius: 10px;
    /*margin-left: 40px;*/
    /*margin-right: 40px;*/
}

.putao-out-bt-su-ct {
    margin-top: 20px;
    width: calc(100% - 80px);
    margin-left: 40px;
    height: 400px;
    font-size: 34px;
    border-radius: 10px;
    word-wrap: break-word;
    /*margin-left: 40px;*/
    /*margin-right: 40px;*/
}

.putao-out-m-title-bk {
    color: white;
    font-size: 36px;
    font-weight: bold;
    margin-right: 40px;
}

.putao-host-out {
    width: 100%;
    min-height: 500px;
}

.putao-host-pass {
    width: 100%;
    height: 1000px;
    background: #faffea;
    font-size: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.putao-host-pass-input {
    margin-top: 50px;
    margin-bottom: 50px;
}

.putao-host-empty {
    font-size: 60px;
    width: 100%;
}

.putao-host-item {
    font-size: 40px;
    min-height: 500px;
    width: 100%;
    margin-top: 50px;
    background: #f3f3f3;
}

.putao-out-m-wait {
    width: 100%;
    height: 100vh;
    background: #f3f3f3;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.putao-out-m-wait-logo {
    height: 200px;
    width: auto;
}

.putao-out-m-wait-notice {
    margin-top: 150px;
    font-size: 36px;
    font-weight: bold;
}