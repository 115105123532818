.info-d-page {
    background: #e3e3e3;
}

.info-d-wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin: 0 auto;
    padding: 100px 0 40px;
    width: 1120px;
    height: auto;
}

.info-d-left {
    padding: 20px;
    width: 800px;
    height: auto;
    background-color: white;
    border: 1px solid #eee;
    box-sizing: border-box;
}

.info-d-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
}

.info-d-user {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    padding: 20px;
    width: 300px;
    height: auto;
    background-color: white;
    border: 1px solid #eee;
}

.info-d-title {
    margin: 0 0 10px 0;
    padding-bottom: 20px;
    width: 100%;
    height: auto;
    text-align: left;
    font-size: 26px;
    font-weight: 700;
    line-height: 50px;
    overflow: hidden;
    border-bottom: 1px solid #e6e6e6;
}

.info-d-back {
    margin-bottom: 8px;
    font-size: 16px;
    color: #7b7b7b;
    cursor: pointer;
}

.info-d-back:hover {
    color: black;
    cursor: pointer;
}

.info-d-top {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 20px 0;
    width: 100%;
    height: auto;
}

.info-d-top-left {
    padding: 35px 20px;
    width: 280px;
    height: 200px;
    border: 1px solid #e9e9e9;
    box-sizing: content-box;
}

.info-d-top-right {
    margin-left: 20px;
    width: calc(100% - 340px);
    height: auto;
}

.info-d-cover {
    width: 280px;
    height: 200px;
}

.info-d-contact {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    width: 100%;
    overflow: auto;
    font-size: 16px;
}

.info-d-contact-left {
    display: inline-block;
    margin-right: 10px;
    width: 92px;
    height: 30px;
    text-align: right;
}

.info-d-contact-right {

}

.tel-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    padding: 0 10px;
    width: auto;
    height: 24px;
    font-size: 12px;
    color: #fF0000;
    border: 1px solid #fF0000;
}

.tel-btn:hover {
    background-color: #fF0000;
    color: white;
    cursor: pointer;
}

.info-d-tip {
    padding: 10px;
    width: 100%;
    height: auto;
    background-color: #F7F7F7;
    font-size: 16px;
}

.info-d-center {
    width: 100%;
    height: auto;
}

.info-d-center-title {
    margin-top: 10px;
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid  #ddd;
    color: #000;
}

.info-d-center-text {
    width: 100%;
    height: auto;
    font-size: 16px;
    overflow: hidden;
    padding: 20px 0 10px;
    line-height: 2;
    overflow-wrap: break-word;
    white-space: pre-wrap;
}

.info-d-center-cover {
    max-width: 600px;
    height: auto;
}

.info-d-more {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    height: 60px;
}

.info-d-more a {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FAFAFA;
    border: 1px #E1E1E1 solid;
    border-radius: 5px;
    font-size: 14px;
    width: 120px;
    text-align: center;
    height: 32px;
    line-height: 32px;
    color: black;
    cursor: pointer;
}

.info-d-more a:hover {
    color: #5ac2fa;
}

.user-img {
    margin: 20px auto 10px;
    border: 1px #d5d5d5 solid;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    transition: transform .4s ease-out;
}

.user-name {
    padding-bottom: 20px;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px #e6e6e6 solid;
}

.user-level {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    width: 100%;
    height: 30px;
    font-size: 14px;
    line-height: 30px;
}


.info-d-type {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 300px;
    height: auto;
    background-color: white;
    border: 1px solid #eee;
}

.info-d-type-title {
    padding-bottom: 10px;
    font-size: 16px;
    color: #000;
    border-bottom: 1px #e6e6e6 solid;
}

.info-d-type-title span {
    padding-bottom: 10px;
    font-weight: bold;
    border-bottom: 1px solid #3592e2;
    color: #3592e2;
}

.info-d-type-txt {
    margin-top: 10px;
    padding: 0;
    font-size: 14px;
    float: left;
    display: block;
    width: 136px;
}