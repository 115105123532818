.upload-info-page-wrap {
    min-width: 1150px;
    min-height: 100vh;
    background: #e3e3e3;
}

.upload-info-page {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin: 0 auto;
    padding: 100px 0 40px;
    width: 800px;
    height: auto;
}

.info-form {
    display: flex;
    flex-direction: column;
    padding: 40px 20px;
    width: 100%;
    height: auto;
    background-color: white;
    border: 1px solid #eee;
    box-sizing: border-box;
}

.form-title {
    margin-bottom: 20px;
    padding-left: 10px;
    width: 100%;
    height: 20px;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    border-left: 4px solid #30bb30;
}

.form-input-wrap {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 10px 0 20px;
    width: 100%;
    height: auto;
}

.form-input-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: baseline;
    margin-right: 10px;
    width: 90px;
    font-size: 16px;
}

.form-input-title-require {
    margin-right: 5px;
    font-size: 14px;
    color: red;
}

.form-input {
    width: calc(100% - 100px);
    height: 40px;
    padding-left: 10px;
    font-size: 16px;
    border: 1px solid #d7d7d7;
}

.form-input:focus {
    padding-left: 10px;
    border: 1px solid #8aaed7;
    outline: none;
}

.form-textarea {
    width: calc(100% - 100px);
    height: 200px;
    padding-left: 10px;
    font-size: 16px;
    border: 1px solid #d7d7d7;
}

.form-cover {
    width: 100px;
    height: 100px;
    border: 1px solid #d7d7d7;
}

.form-cover-img {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.upload-label {
    width: 100%;
    height: 100%;
}

.input-file {
    display: none;
}

.upload-btn {
    width: 30%;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 4px;
    text-indent: 4px;
    font-size: 18px;
    color: white;
    background: #3c80d4;
    margin: 30px auto 0;
    cursor: pointer;
    transition-property: background;
    -moz-transition-property: background; /* Firefox 4 */
    -webkit-transition-property:background; /* Safari and Chrome */
    -o-transition-property:background; /* Opera */
    transition-duration: 100ms;
    -moz-transition-duration: 100ms;
    -webkit-transition-duration: 100ms;
    -o-transition-duration: 100ms;
    transition-timing-function: linear, ease;
    -moz-transition-timing-function: linear, ease;
    -webkit-transition-timing-function: linear, ease;
    -o-transition-timing-function: linear, ease;
}

.upload-btn:hover {
    background: #518cd5;
}