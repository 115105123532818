/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.one {
  position: relative;
  z-index: 1;
  width: 100%;
  min-width: 1450px;
  height: 700px;
}
.two {
  position: relative;
  z-index: 1;
  width: 100%;
  min-width: 1450px;
  background-image: url("../picture/bg-one.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}
.three {
  position: relative;
  z-index: 1;
  width: 100%;
  min-width: 1450px;
  background-image: url("../picture/bg-one.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}
.four {
  position: relative;
  z-index: 1;
  width: 100%;
  background-color: #616161;
}
.five {
  position: relative;
  z-index: 10;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.9);
}
.background-fixed-img {
  width: 100%;
  min-width: 1430px;
  transition: ease 3s;
  left: 0;
  top: 0;
  height: 100%;
  overflow: hidden;
  z-index: 99;
}
.bg-wrapper {
  position: relative;
  display: flex;
  z-index: 4;
  width: 100%;
  min-width: 1450px;
  height: 1800px;
}
.bg-wrapper-bg {
  position: relative;
  z-index: 1;
  width: 100%;
  background-image: url("../picture/bgCity.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.bg-wrapper-area {
  position: relative;
  width: 100%;
  height: 100%;
}
.bg-wrapper-img-area {
  position: relative;
  width: 100%;
  height: 100%;
}
.bg-wrapper-img {
  position: absolute;
  bottom: 0;
  left: 40%;
  width: 20%;
  height: 90%;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.bg-wrapper-imgS {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: auto;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.bg-wrapper-text-area-about {
  position: absolute;
  top: 20%;
  left: 55%;
  width: 25%;
}
.bg-wrapper-text-area-about-content {
  position: relative;
  width: 100%;
  font-size: 16px;
  color: #2b618a;
}
.bg-wrapper-text-area-about-content h2 {
  color: #2b618a;
  border-bottom: 2px solid #2b618a;
}
.bg-wrapper-text-area-culture {
  position: absolute;
  top: 45%;
  right: 60%;
  width: 25%;
}
.bg-wrapper-text-area-culture-content {
  position: relative;
  width: 100%;
  font-size: 16px;
  color: #e4f3fd;
}
.bg-wrapper-text-area-culture-content h2 {
  color: #e4f3fd;
  border-bottom: 2px solid #e4f3fd;
}
.bg-wrapper-text-area-target {
  position: absolute;
  top: 65%;
  left: 58%;
  width: 25%;
}
.bg-wrapper-text-area-target-content {
  position: relative;
  width: 100%;
  font-size: 16px;
  color: #ffffff;
}
.bg-wrapper-text-area-target-content h2 {
  color: #ffffff;
  border-bottom: 2px solid #ffffff;
}
.About .content-wrapper {
  padding-top: 200px;
  position: relative;
  z-index: 4;
  width: 100%;
  min-width: 1450px;
  height: 800px;
}
.About .content-wrapper .image-wrapper {
  position: relative;
  float: left;
  width: 50%;
  min-width: 700px;
  height: 100%;
  min-height: 600px;
  transform: translateX(-100px);
  opacity: 0;
}
.About .content-wrapper .text-wrapper {
  position: relative;
  float: left;
  width: 45%;
  margin-left: 2%;
  top: 15%;
  font-family: "微软雅黑";
  text-align: left;
}
.About .content-wrapper .text-wrapper h2,
.About .content-wrapper .text-wrapper-bottom h2 {
  font-family: "微软雅黑";
  font-size: 32px;
  font-weight: bold;
  white-space: nowrap;
  color: #ffffff;
}
.About .content-wrapper .text-wrapper p {
  margin: 40px 0 20px 0;
  padding: 0;
  color: #ffffff;
  font-family: "微软雅黑";
  font-size: 20px;
  line-height: 20px;
  white-space: nowrap;
}
.About .content-wrapper .text-wrapper .line {
  position: absolute;
  width: 150px;
  height: 10px;
  top: 50px;
  left: 0px;
  background: #99A9BF;
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.03));
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.03));
  background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.03));
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.03));
}
.Culture .content-wrapper {
  padding-bottom: 150px;
  position: relative;
  z-index: 5;
  width: 100%;
  min-width: 1450px;
  height: 800px;
}
.Culture .content-wrapper .image-wrapper {
  position: relative;
  float: right;
  width: 50%;
  min-width: 700px;
  transform: translateX(-100px);
  opacity: 0;
}
.Culture .content-wrapper .text-wrapper {
  position: relative;
  float: right;
  width: 40%;
  min-width: 600px;
  margin-right: 2%;
  top: 15%;
  font-family: "微软雅黑";
  text-align: right;
}
.Culture .content-wrapper .text-wrapper h2,
.Culture .content-wrapper .text-wrapper-bottom h2 {
  white-space: nowrap;
  font-family: "微软雅黑";
  color: #ffffff;
  font-size: 32px;
  font-weight: bold;
}
.Culture .content-wrapper .text-wrapper p {
  margin: 40px 0 20px 0;
  padding: 0;
  font-size: 20px;
  line-height: 20px;
  color: #ffffff;
  white-space: nowrap;
  font-family: "微软雅黑";
}
.Culture .content-wrapper .text-wrapper .line {
  position: absolute;
  width: 150px;
  height: 10px;
  background: #99A9BF;
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.03));
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.03));
  background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.03));
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.03));
  top: 50px;
  right: 0;
}
.Target .content-wrapper {
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
  width: 100%;
  min-width: 1450px;
  height: 800px;
  background-image: url("../picture/bg-two.jpg");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}
.Target .content-wrapper .image-wrapper {
  position: relative;
  z-index: 8;
  float: left;
  width: 50%;
  min-width: 700px;
  transform: translateX(-100px);
  opacity: 0;
}
.Target .content-wrapper .text-wrapper {
  position: relative;
  text-align: left;
  margin-left: 2%;
  top: 10%;
  float: left;
  width: 40%;
  min-width: 600px;
  font-family: "微软雅黑", serif;
}
.Target .content-wrapper .text-wrapper h2,
.Target .content-wrapper .text-wrapper-bottom h2 {
  font-family: "微软雅黑", serif;
  font-size: 32px;
  font-weight: bold;
  white-space: nowrap;
  color: #ffffff;
}
.Target .content-wrapper .text-wrapper p {
  margin: 40px 0 20px 0;
  padding: 0;
  font-family: "微软雅黑", serif;
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  white-space: nowrap;
  color: #475668;
}
.Target .content-wrapper .left-text {
  padding-left: 10%;
}
.Target .content-wrapper .text-wrapper .line {
  width: 200px;
  height: 10px;
  position: absolute;
  background: #99A9BF;
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.03));
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.03));
  background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.03));
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.03));
  top: 50px;
  left: 0;
}
.Team .content-wrapper {
  position: relative;
  z-index: 7;
  width: 100%;
  min-width: 1450px;
  height: 880px;
  background: black;
}
.Team .image-wrapper {
  position: relative;
  z-index: 8;
  float: bottom;
  width: 100%;
  height: 100%;
  transform: translateY(0);
  opacity: 0.95;
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
}
.Team .text-wrapper {
  position: relative;
  z-index: 12;
  width: 30%;
  margin: 0 auto;
  top: -90%;
  font-family: "微软雅黑";
  text-align: center;
}
.Team .text-wrapper h2,
.Team .text-wrapper h2 {
  font-size: 32px;
  color: #575757;
  font-weight: bold;
  white-space: nowrap;
  font-family: "微软雅黑";
}
.Team .text-wrapper p {
  margin: 20px 0 20px 0px;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
  white-space: nowrap;
  font-family: "微软雅黑";
}
.Team .brand-item-team {
  position: relative;
  z-index: 13;
  width: 1000px;
  height: 250px;
  top: -93%;
  margin-left: auto;
  margin-right: auto;
}
.Team .brand-item-team-container {
  margin-top: 100px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 250px);
}
.Team .team-profile-front {
  position: relative;
  width: 250px;
  height: 250px;
  cursor: pointer;
}
.Team .team-profile-front .team-profile-front-image {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  position: relative;
  z-index: 5;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.Team .team-profile-front .team-profile-front-content {
  position: absolute;
  z-index: 6;
  top: 80%;
  padding-left: 20px;
  width: 100%;
  height: 20%;
  background: rgba(127, 127, 127, 0.7);
}
.Team .team-profile-front .team-profile-front-content-heading {
  position: relative;
  font-size: 18px;
  font-weight: bold;
}
.Team .team-profile-front .team-profile-front-content-title {
  position: relative;
  font-size: 14px;
  color: white;
}
.Team .team-profile-front .team-profile-front-content-description {
  display: none;
}
.Team .team-profile-back {
  width: 250px;
  height: 250px;
  background: rgba(255, 255, 255, 0.5);
  cursor: pointer;
}
.Team .team-profile-back .team-profile-back-image {
  display: none;
}
.Team .team-profile-back .team-profile-back-content {
  padding: 20px;
}
.Team .team-profile-back .team-profile-back-content-heading {
  position: relative;
  font-size: 22px;
  color: #000000;
}
.Team .team-profile-back .team-profile-back-content-title {
  position: relative;
  padding-bottom: 10px;
  font-size: 18px;
  color: #000000;
  border-bottom: 1px solid #000000;
}
.Team .team-profile-back .team-profile-back-content-description {
  position: relative;
  padding-top: 20px;
  font-size: 14px;
  color: #000000;
}
.image1 {
  position: relative;
  align-items: center;
  height: 100%;
  background: url("../picture/BackgroundAbout.jpg") no-repeat center / cover;
  background-origin: content-box;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.image2 {
  position: relative;
  height: 100%;
  background: url("../picture/BackgroundCulture.jpg") no-repeat center / cover;
  background-origin: content-box;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.image3 {
  position: relative;
  height: 90%;
  background: url("../picture/BackgroundTarget.jpg") no-repeat center / cover;
  background-origin: content-box;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.image4 {
  position: relative;
  width: 100%;
  background: url("../picture/BackgroundTeam.jpg") no-repeat center / cover;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.card-f {
  width: 350px;
  height: 350px;
}
.card-back {
  width: 350px;
  height: 350px;
  background: #fcfcfc;
  transform: scale(1);
}
.fade-enter {
  opacity: 0.2;
  transform: scale(1);
}
.fade-enter-active {
  opacity: 1;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transition: color 0.3s ease 0s, opacity 1s cubic-bezier(0.38, 0.32, 0.36, 0.98) 0s, transform 1s cubic-bezier(0.38, 0.32, 0.36, 0.98) 0s;
}
.fade-exit {
  opacity: 1;
  transform: scale(1);
}
.fade-exit-active {
  opacity: 0.8;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transition: color 0.3s ease 0s, opacity 1s cubic-bezier(0.38, 0.32, 0.36, 0.98) 0s, transform 1s cubic-bezier(0.38, 0.32, 0.36, 0.98) 0s;
}
.mobile-background-page {
  width: 100%;
}
.mobile-background-page .mobile-banner .page {
  width: 100%;
  height: 600px;
}
.mobile-background-page .mobile-banner .banner-wrapper {
  background: url("../picture/BackgroundBanner.jpg") no-repeat center / cover;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.mobile-background-page .mobile-banner .mobile-cover {
  position: relative;
  z-index: 90;
  width: 100%;
  height: 600px;
  background-color: rgba(158, 185, 255, 0.25);
}
.mobile-background-page .mobile-banner .banner-text-wrapper {
  position: relative;
  right: 25%;
  top: -430px;
  color: #fff;
  text-align: right;
}
.mobile-background-page .mobile-banner .banner-text-wrapper h2 {
  font-size: 88px;
  font-weight: bold;
  white-space: nowrap;
  color: #e2e2e2;
  margin-bottom: 10px;
}
.mobile-background-page .mobile-banner .banner-text-wrapper h2 p {
  font-size: 70px;
  color: rgba(106, 253, 253, 0.85);
  display: inline-block;
  margin: 0;
}
.mobile-background-page .mobile-banner .banner-text-wrapper p {
  font-size: 44px;
  color: #ffffff;
  margin: 28px auto 24px;
}
.mobile-background-page .mobile-one {
  position: relative;
  width: 100%;
  height: 600px;
  background-color: #ffffff;
}
.mobile-background-page .mobile-two {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 35%;
  min-height: 600px;
}
.mobile-background-page .mobile-three {
  position: relative;
  z-index: 1;
  width: 100%;
  background-color: #62ff00;
}
.mobile-background-page .mobile-four {
  position: relative;
  z-index: 1;
  width: 100%;
  background-color: #16e7ce;
}
.mobile-background-page .mobile-five {
  position: relative;
  z-index: 1;
  width: 100%;
  background-color: #ffffff;
}
.mobile-background-page .mobile-about {
  width: 100%;
}
.mobile-background-page .mobile-about .content-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  width: 100%;
  height: 35%;
}
.mobile-background-page .mobile-about .content-wrapper .text-wrapper {
  position: relative;
  width: 40%;
  min-width: 768px;
}
.mobile-background-page .mobile-about .content-wrapper .text-wrapper h2,
.mobile-background-page .mobile-about .content-wrapper .text-wrapper-bottom h2 {
  font-size: 45px;
  font-weight: bold;
  white-space: nowrap;
}
.mobile-background-page .mobile-about .content-wrapper .text-wrapper p {
  margin: 40px 0 20px 0px;
  line-height: 28px;
  color: #06080a;
  font-size: 20px;
  white-space: nowrap;
}
.mobile-background-page .mobile-culture {
  width: 100%;
  height: 100%;
}
.mobile-background-page .mobile-culture .content-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  width: 100%;
  height: 35%;
  min-height: 600px;
  background: #70e1f5;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #ffd194, #70e1f5);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #ffd194, #70e1f5);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.mobile-background-page .mobile-culture .content-wrapper .text-wrapper {
  position: relative;
  margin: auto;
  width: 40%;
  min-width: 768px;
}
.mobile-background-page .mobile-culture .content-wrapper .text-wrapper h2,
.mobile-background-page .mobile-culture .content-wrapper .text-wrapper-bottom h2 {
  font-size: 45px;
  color: #17417f;
  font-weight: bold;
  white-space: nowrap;
  text-shadow: 1px 1px rgba(197, 223, 248, 0.8), 2px 2px rgba(197, 223, 248, 0.8), 3px 3px rgba(197, 223, 248, 0.8), 4px 4px rgba(197, 223, 248, 0.8);
}
.mobile-background-page .mobile-culture .content-wrapper .text-wrapper p {
  margin: 40px 0 20px 0px;
  font-size: 20px;
  line-height: 28px;
  color: #06080a;
  white-space: nowrap;
}
.mobile-background-page .mobile-target {
  width: 100%;
  height: 100%;
}
.mobile-background-page .mobile-target .content-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  width: 100%;
  height: 35%;
  min-height: 600px;
  background: #00416A;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #E4E5E6, #00416A);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #E4E5E6, #00416A);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.mobile-background-page .mobile-target .content-wrapper .text-wrapper {
  position: relative;
  margin: auto;
  width: 40%;
  min-width: 768px;
}
.mobile-background-page .mobile-target .content-wrapper .text-wrapper h2,
.mobile-background-page .mobile-target .content-wrapper .text-wrapper-bottom h2 {
  font-size: 45px;
  color: #17417f;
  font-weight: bold;
  white-space: nowrap;
  text-shadow: 1px 1px rgba(197, 223, 248, 0.8), 2px 2px rgba(197, 223, 248, 0.8), 3px 3px rgba(197, 223, 248, 0.8), 4px 4px rgba(197, 223, 248, 0.8);
}
.mobile-background-page .mobile-target .content-wrapper .text-wrapper p {
  margin: 40px 0 20px 0px;
  font-size: 20px;
  line-height: 28px;
  white-space: nowrap;
  color: #06080a;
}
.mobile-background-page .mobile-team {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background: #e8e8e8;
}
.mobile-background-page .mobile-team .content-wrapper {
  position: relative;
  z-index: 4;
  width: 100%;
  height: 1800px;
}
.mobile-background-page .mobile-team .text-wrapper {
  position: relative;
  background-image: url("../picture/MobileTeam.png");
  background-size: 100% 100%;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  height: 240px;
  width: 100%;
}
.mobile-background-page .mobile-team .text-wrapper-cover {
  text-align: center;
  height: 240px;
  width: 100%;
  background: rgba(32, 87, 153, 0.5);
}
.mobile-background-page .mobile-team .text-wrapper h2,
.mobile-background-page .mobile-team .text-wrapper-bottom h2 {
  padding-top: 40px;
  font-size: 50px;
  color: #ffffff;
  font-weight: bold;
  white-space: nowrap;
}
.mobile-background-page .mobile-team .text-wrapper p {
  margin: 0 0 20px 0;
  font-size: 34px;
  line-height: 28px;
  white-space: nowrap;
  color: #ffffff;
  font-style: italic;
}
.mobile-background-page .mobile-team .mobile-team-info {
  width: 100%;
}
.mobile-background-page .mobile-team .mobile-background-team-profile {
  width: 96%;
  height: 350px;
  margin: 14px auto 14px;
  background: rgba(255, 255, 255, 0.5);
}
.mobile-background-page .mobile-team .mobile-background-team-profile-title {
  text-align: center;
  height: 350px;
  width: 350px;
  margin-right: 30px;
  /*background: #3682a0;*/
  float: left;
}
.mobile-background-page .mobile-team .mobile-background-team-profile-title-sign {
  width: 260px;
  height: 260px;
  margin: 46px auto;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.mobile-background-page .mobile-team .mobile-background-team-profile-title-content {
  font-size: 30px;
  font-weight: bold;
}
.mobile-background-page .mobile-team .mobile-background-team-profile-content {
  height: 250px;
  padding-top: 50px;
}
.mobile-background-page .mobile-team .mobile-background-team-profile-position {
  padding-top: 5px;
  font-size: 30px;
  font-weight: bold;
}
.mobile-background-page .mobile-team .mobile-background-team-profile-description {
  padding-top: 10px;
  width: 90%;
  font-size: 30px;
  margin-right: 5px;
}
.mobile-bk-content {
  width: 100%;
  height: 1500px;
  background: #ffffff;
}
.mobile-bk-intro {
  width: 70%;
  margin: auto;
  padding-top: 100px;
  height: 1500px;
  background: white;
}
.mobile-intro-title {
  font-size: 50px;
  text-align: center;
  font-weight: bold;
}
.mobile-intro-content {
  padding-top: 30px;
  font-size: 32px;
  margin-bottom: 80px;
}
.mobile-sub-title {
  margin: 80px auto auto;
  line-height: 40px;
  height: 40px;
  font-size: 36px;
  padding-left: 10px;
  text-align: center;
  color: #486789;
  font-weight: bold;
}
.mobile-sub-content {
  padding-top: 30px;
  font-size: 32px;
}
