//@import '~antd/dist/antd.css';

.news-page {
    width: 100%;
    background: #799BBABF;
}

.news-banner {
    margin: auto;
    background-image: url("./picture/NewsBK.jpg");
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    background-size: 100% 100%;
}

.news-box {
    margin: 50px auto 50px;
    width: 1400px;
    background: #dedede;
}

.news-title {
    //width: 1400px;
    padding-top: 40px;
    padding-bottom: 36px;
    padding-left: 70px;
    line-height: 46px;
    font-family: "微软雅黑";
    font-size: 24px;
    font-weight: bold;
    color: #000000;
    //background: rgba(232, 232, 232, 0.39);
}

.news-container {
    padding-bottom: 50px;
    //background: rgba(232, 232, 232, 0.39);
}

.news-card {
    display: flex;
    margin: 10px auto;
    width: 90%;
    height: 200px;
    cursor: pointer;
    border-radius: 15px;
    //border-top: 1px solid #c1c1c1;
    .news-cover {
        float: left;
        width: 200px;
        height: 200px;
        border-radius: 15px 0 0 15px;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
    }
    .news-content {
        float: left;
        padding: 20px;
        height: 200px;
        border-radius: 0 15px 15px 0;
        background: #8c8989;
    }
    .news-card-title {
        font-size: 24px;
    }
    .news-card-date {
        margin-top: 10px;
        font-size: 14px;
        color: #8c8989;
    }
    .news-card-brief {
        margin-top: 20px;
        font-size: 14px;
    }
}



.mobile-news-page {
    background: #e6f2ff;
    width: 100%;
}

.mobile-news-banner {
    margin: auto;
    height: 360px;
    background-image: url("./picture/NewsBK.jpg");
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    background-size: 100% 100%;
}

.mobile-news-card {
    background: white;
    height: 300px;
    width: 98%;
    margin: 20px 1%;
}

.mobile-news-cover {
    float: left;
    width: 300px;
    height: 300px;
}

.mobile-news-content {
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 14px;
    float: left;
    position: relative;
    height: 300px;
}

.mobile-news-card-title {
    font-weight: bold;
    font-size: 34px;
}

.mobile-news-card-brief {
    padding-top: 10px;
    font-size: 30px;
}

.mobile-news-card-brief-content {
    float: left;
    font-size: 30px;
}

.mobile-news-card-brief-more {
    color: #427fe0;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 8px;
    font-size: 30px;
}

.mobile-news-card-date {
    color: #828282;
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: 8px;
    font-size: 28px;
}