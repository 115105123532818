@import '~antd/dist/antd.css';

.ant-carousel .slick-slide {
    text-align: center;
    overflow: hidden;
}

.ant-carousel .slick-slide h1 {
    color: #f38383;
    height: 100%;
}

.ant-carousel .slick-slide h1 img {
    width: 100%;
    height: 70vh !important;
    overflow: hidden;
}

.home-page {
    min-height: 100%;
    height: 100%;
    min-width: 1450px;
    background-color: #ffffff;
}

.mobile-home-page {
    height: 100%;
    width: 100%;
    background-color: #ffffff;
}

.bc-tag {
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    background: #ffffff;
    height: 30vh;
    min-width: 1450px;
    width: 100%;
    position: relative;
    margin: auto;
    z-index: 2;
}

.home-slogan-pic {
    height: 100%;
    width: 100%;
    position: absolute;
    right: 0;
}

.slogan-des {
    position: relative;
    height: 30vh;
    width: 100%;
    border-top: 0.5vh solid #466ea1;
    border-bottom: 0.5vh solid #466ea1;
}

.slogan-line-cn {
    height: 60%;
    background: white;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -ms-background-clip: text;
    color: transparent;
    font-size: 4.5vh;
    font-weight: bold;
    padding-left: 3%;
    padding-right: 1%;
    float: left;
}

.slogan-line-en {
    padding-left: 14%;
    float: left;
    height: 40%;
    color: #466ea1;
    font-size: 3.5vh;
    font-weight: bold;
    line-height: 5vh;
}

.slogan-border {
    border-width: 0.4vh 0.4vh 0 0;
    display: inline-block;
    border-style: solid;
    border-color: #466ea1;
    height: 21.1vh;
    width: 21.2vh;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    position: absolute;
    bottom: 4vh;
    z-index: 0;
    left: 20%;
    overflow: hidden;
}

.slogan-line-cn-word {
    height: 4.5vh;
    line-height: 29vh;
}

.slogan-line-en-word {
    height: 30vh;
    line-height: 29.5vh;
}

.slider-show {
    min-width: 1430px;
    width: 100%;
    position: fixed !important;
    overflow: hidden;
    z-index: 1;
}

.h-banner-image-level-one {
    text-align: center !important;
    height: 100% !important;
    width: 100% !important;
    cursor: pointer;
}

.slider-show-image {
    height: 100% !important;
    width: 100% !important;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.banner-image-content {
    height: 38vh;
    width: 50vh;
    background: rgba(255, 255, 255, 0.25);
    position: absolute;
    top: 14vh;
    left: 8%;
    text-align: left;

    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    /*background-image: linear-gradient(135deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), linear-gradient(135deg, rgba(90, 181, 220, 0.2), rgba(222, 222, 222, 0.2));*/

    border-radius: 0.6vh 28vh 0.6vh 30vh;
    border-bottom: 1vh solid rgba(255, 255, 255, 0.6);
    /*background: white;*/
}

@media (prefers-reduced-motion: no-preference) {
    .pointer-arrow-dir {
        animation: dir-up normal 0.2s linear;
    }
}

@keyframes dir-up {
    from {
        transform: translateY(5vh);
    }
    to {
        transform: translateY(0);
    }
}


.banner-service {
    font-size: 2vh;
    color: #e3e3e3;
    height: 60%;
    width: 100%;
    background: transparent;
    margin-top: 12%;
}

.service-building-level {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}

.pointer-arrow {
    width: 6vh;
    position: absolute;
    /*image-rendering: -moz-crisp-edges;*/
    /*image-rendering: -o-crisp-edges;*/
    /*image-rendering: -webkit-optimize-contrast;*/
    /*image-rendering: crisp-edges;*/
    /*-ms-interpolation-mode: nearest-neighbor;*/
}

.banner-split {
    width: 2%;
    height: 60%;
    position: absolute;
    left: 49%;
    top: 14%;
}

.banner-service-table {
    width: 100%;
    height: 74%;
    background: transparent;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.banner-service-glass {
    width: 46vh;
    height: 70vh;
    background: rgba(50,85,139,0.76);
    position: fixed;
    right: 0;
    top: 0;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
    z-index: 2;
}

.pointer-arrow-dir {
    pointer-events: none;
    width: 6vh;
    position: absolute;
    /*image-rendering: -moz-crisp-edges;*/
    /*image-rendering: -o-crisp-edges;*/
    /*image-rendering: -webkit-optimize-contrast;*/
    /*image-rendering: crisp-edges;*/
    /*-ms-interpolation-mode: nearest-neighbor;*/
}

.banner-ser-img {
    width: 78%;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    cursor: pointer;
}

.banner-image-item {
    height: 56vh;
    width: 52vh;
    position: absolute;
    top: 0;
    left: 8%;
    z-index: 8;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.banner-image-adv {
    /*height: 56vh;*/
    height: 30vh;
    width: 52vh;
    position: absolute;
    /*top: 4vh;*/
    top: 18vh;
    left: 8%;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    z-index: 2;
}

.banner-image-adv-mb-title {
    height: 900px;
    top: -250px;
    width: 760px;
    left: 0;
    position: absolute;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}
.banner-image-adv-mb-content {
    height: 180px;
    width: auto;
    margin-top: 420px;
    position: relative;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}


.banner-image-light {
    height: 68vh;
    width: 52vh;
    position: absolute;
    top: 0;
    left: 8%;
    z-index: 1;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.banner-image-title {
    padding-top: 5vh;
    white-space: nowrap;
    text-align: right;
    text-shadow: 0.18vh 0.18vh 0 rgba(33, 59, 88, 0.9);
    /*0.24vh 0.24vh 0 #ffffff;*/
    font-size: 6.6vh;
    font-weight: bold;
    line-height: 5vh;
    color: rgb(95, 188, 255);
}

.banner-image-title p {
    text-shadow: 0 0 0 rgba(172, 172, 172, 0);
    letter-spacing: 0.6vh;
    padding-right: 2.4vh;
    padding-left: 2.8vh;
    display: inline-block;
    margin: 0;
    font-size: 4.4vh;
    color: rgb(241, 241, 241);
}

.banner-image-text {
    letter-spacing: 0.3vh;
    padding-right: 8vh;
    text-align: right;
    margin-top: 5vh;
    font-size: 2.5vh;
    line-height: 4.5vh;
    color: #ffffff;
}

.mobile-home-page-banner {
    width: 100%;
    height: 600px;
}

.mobile-home-banner {
    width: 100%;
    height: 100%;
    /*background-size: 100% 100%;*/
    background: url("../picture/home-banner-one-M.jpg") no-repeat center / cover;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.mobile-banner-image-content {
    width: 100%;
    position: relative;
    height: 600px;
    text-align: center;
}

.mobile-banner-image-title {
    text-align: right;
    white-space: nowrap;
    font-size: 60px;
    font-weight: bold;
    line-height: 50px;
    color: #3780ef;
}

.mobile-banner-image-title p {
    letter-spacing: 3px;
    display: inline-block;
    margin: 0;
    font-size: 50px;
    color: #71b9cd;
}

.mobile-banner-image-text {
    text-align: right;
    margin-top: 30px;
    font-size: 30px;
    line-height: 50px;
    color: #ffffff;
}


.home-empty-top {
    height: 70vh;
    opacity: 0;
    z-index: 0;
}

.home-empty-button {
    height: 200px;
    opacity: 0;
    z-index: 0;
}