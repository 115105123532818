@font-face {
  font-family: '';
  src: url("");
}

.service-banner {
  position: relative;
  width: 100%;
  min-width: 1450px;
  height: 100vh;
  //background: #5b9e20;
  .service-banner-narrow-empty {
    position: relative;
    width: 100%;
    height: 50px;
  }
  .service-banner-empty {
    position: relative;
    width: 100%;
    height: 80px;
    background: #545961;
    //background: #8c8989;
  }
  .swiper-container-narrow {
    position: relative;
    width: 100%;
    height: calc(~'100vh - 50px');
    //height: 100%;
    background: #99A9BF;
  }
  .swiper-container {
    position: relative;
    width: 100%;
    height: calc(~'100vh - 80px');
    //height: 100%;
    background: #99A9BF;
  }
  .service-banner-list {

  }
  .swiper-wrapper {

  }
  .swiper-slide {

  }

.service-banner-one {
  position: relative;
  z-index: auto;
  width: 100%;
  height: 100%;
  background: no-repeat;
  //background-size: 100% 100%;
  background-size: cover;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.service-banner-two {
  position: relative;
  z-index: auto;
  width: 100%;
  height: 100%;
  background: no-repeat;
  background-size: 100% 100%;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

.service-anchor {
  position: absolute;
  z-index: 5;
  right: 0;
  bottom: 0;
  width: 100%;
  //background: #3779ec;
  .service-anchor-relative {
    position: relative;
    width: 100%;
    height: 100px;
  }
  .service-anchor-title {
    position: relative;
    margin: 0 0 50px 10%;
    width: 10%;
  }
  .service-anchor-title h2 {
    font-family: "微软雅黑", serif;
    font-weight: bold;
    font-size: 24px;
    color: #fff;
  }
  .service-anchor-title span {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
  }
  .service-anchor-box {
    //position: absolute;
    //left: 0;
    //bottom: 0;
    clear: both;
    z-index: 10;
    width: 100%;
  }
  .service-anchor-box-one {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 20%;
    height: 20px;
    //background: #dbd8c2;
  }
  .service-anchor-box-two {
    position: absolute;
    left: 20%;
    bottom: 0;
    width: 20%;
    height: 20px;
    background: rgba(64, 106, 142, 0.8);
    cursor: pointer;
    .service-anchor-box-content {
      display: none;
    }
  }
  .service-anchor-box-two-back {
    position: absolute;
    left: 20%;
    bottom: 0;
    width: 20%;
    height: 156px;
    background: rgba(64, 106, 142, 0.7);
    cursor: pointer;
    .service-anchor-box-content {
      padding: 10px;
      font-size: 28px;
    }
    .service-anchor-box-content-title {
      position: relative;
      padding-bottom: 10px;
      border-bottom: 2px solid rgba(255,255,255,.2);
      font-size: 22px;
      font-weight: bold;
      color: #fff;
    }
    .service-anchor-box-content-text {
      display: flex;
      padding-top: 10px;
      flex-wrap: wrap;
      font-size: 16px;
      color: #fff;
    }
    .service-anchor-box-content-text li {
      width: 50%;
      font-size: 16px;
    }
  }
  .service-anchor-box-three {
    position: absolute;
    left: 40%;
    bottom: 0;
    width: 20%;
    height: 20px;
    background: rgba(95, 116, 134, 0.8);
    cursor: pointer;
    .service-anchor-box-content {
      display: none;
    }
  }
  .service-anchor-box-three-back {
    position: absolute;
    left: 40%;
    bottom: 0;
    width: 20%;
    height: 156px;
    background: rgba(95, 116, 134, 0.7);
    cursor: pointer;
    .service-anchor-box-content {
      padding: 10px;
      font-size: 28px;
    }
    .service-anchor-box-content-title {
      position: relative;
      padding-bottom: 10px;
      border-bottom: 2px solid rgba(255,255,255,.2);
      font-size: 22px;
      font-weight: bold;
      color: #fff;
    }
    .service-anchor-box-content-text {
      display: flex;
      padding-top: 10px;
      flex-wrap: wrap;
      font-size: 16px;
      color: #fff;
    }
    .service-anchor-box-content-text li {
      width: 100%;
      font-size: 16px;
    }
  }
  .service-anchor-box-four {
    position: absolute;
    left: 60%;
    bottom: 0;
    width: 20%;
    height: 20px;
    background: rgba(121, 148, 172, 0.8);
    cursor: pointer;
    .service-anchor-box-content {
      display: none;
    }
  }
  .service-anchor-box-four-back {
    position: absolute;
    left: 60%;
    bottom: 0;
    width: 20%;
    height: 156px;
    background: rgba(121, 148, 172, 0.7);
    cursor: pointer;
    .service-anchor-box-content {
      padding: 10px;
      font-size: 28px;
    }
    .service-anchor-box-content-title {
      position: relative;
      padding-bottom: 10px;
      border-bottom: 2px solid rgba(255,255,255,.2);
      font-size: 22px;
      font-weight: bold;
      color: #fff;
    }
    .service-anchor-box-content-text {
      display: flex;
      padding-top: 10px;
      flex-wrap: wrap;
      font-size: 16px;
      color: #fff;
    }
    .service-anchor-box-content-text li {
      width: 100%;
      font-size: 16px;
    }
  }
  .service-anchor-box-five {
    position: absolute;
    left: 80%;
    bottom: 0;
    width: 20%;
    height: 20px;
    background: rgba(149, 158, 167, 0.8);
    cursor: pointer;
    .service-anchor-box-content {
      display: none;
    }
  }
  .service-anchor-box-five-back {
    position: absolute;
    left: 80%;
    bottom: 0;
    width: 20%;
    height: 156px;
    background: rgba(149, 158, 167, 0.7);
    cursor: pointer;
    .service-anchor-box-content {
      padding: 10px;
      font-size: 28px;
    }
    .service-anchor-box-content-title {
      position: relative;
      padding-bottom: 10px;
      border-bottom: 2px solid rgba(255,255,255,.2);
      font-size: 22px;
      font-weight: bold;
      color: #fff;
    }
    .service-anchor-box-content-text {
      display: flex;
      padding-top: 10px;
      flex-wrap: wrap;
      font-size: 16px;
      color: #fff;
    }
    .service-anchor-box-content-text li {
      width: 100%;
      font-size: 16px;
    }
  }
}
}

.mobile-service-page-banner {
  width: 100%;
  height: 600px;
  //background: url("./picture/service-banner-one.jpg") no-repeat center / cover;
  .mobile-service-banner {
    width: 100%;
    height: 100%;
    background: url("./picture/service-banner-one.jpg") no-repeat;
    background-size: 100% 100%;
    //background-size: cover;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }
}