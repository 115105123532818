@import '~antd/dist/antd.css';

.ant-card-body {
    padding-top: 10px;
}

.level-two {
    height: 665px;
    background-image: url("../picture/ServiceLayer.jpg");
    background-size: cover;
    min-width: 1450px;
    position: relative;
    text-align: center;
    z-index: 6;
    opacity: 1;
}

/*.ant-card-meta-title {*/
/* color: #9e1d4a;*/
/*}*/

.service-tag-layer {
    height: 300px;
    /*background: #5fa4f1;*/
    /*position: fixed;*/
    background: #5fa4f1 fixed;
    /*position: fixed;*/
    z-index: 6;
}

.level-one {
    width: 100%;
    min-width: 1450px;
    min-height: 1020px;
    background-color: #ffffff;
    position: relative;
    z-index: 3;
}

.mobile-level-one {
    width: 100%;
    /*min-width: 1450px;*/
    min-height: 1020px;
    background-color: #ffffff;
    position: relative;
    z-index: 3;
}

.home-cards-layer {
    width: 1430px;
    height: 680px;
    margin: auto;
    /*background: rgba(255, 255, 255, 0);*/
    border-radius: 10px 10px 10px 10px;
    background-image: url("../picture/ServiceBK.jpg");
    background-size: 100% 100%;
}

.service-collections {
    background-color: rgba(38, 56, 100, 0.1);
    height: 345px;
    width: 1430px;
    margin: auto;
}

.service-empty-layer {
    height: 100px;
    background: rgba(255, 255, 255, 0);
}

.card-service {
    text-align: center;
    float: left;
    width: 341px;
    margin-left: 8px;
    margin-right: 8px;
    height: 350px;
    /*background-color: rgba(38, 56, 100, 0.1);*/
    background-color: rgba(38, 56, 100, 0);
    border-color: rgba(255, 255, 255, 0);
}

.mobile-card-service {
    /*text-align: center;*/
    float: left;
    width: 96%;
    margin-right: 2%;
    margin-left: 2%;
    height: 300px;
    margin-bottom: 30px;
    /*background: rgba(177, 212, 255, 0.5);*/
}

.card-service-enter {
    text-align: center;
    float: left;
    width: 341px;
    margin-left: 8px;
    margin-right: 8px;
    height: 345px;
    background-color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

.title-sign-before {
    width: 110px;
    height: 110px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 50%;
}

.mobile-title-sign {
    background: #ffffff;
    width: 230px;
    height: 230px;
    margin: 40px auto auto;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    z-index: 2;
}

.mobile-card-title-content {
    padding-top: 30px;
    padding-bottom: 10px;
    font-size: 30px;
    font-weight: bold;
}

.mobile-card-service-title {
    text-align: center;
    height: 300px;
    width: 300px;
    margin-right: 30px;
    /*background: #3682a0;*/
    /*background: rgba(139, 216, 255, 0.3);*/
    float: left;
}

.mobile-card-description {
    font-size: 30px;
    width: 80%;
    height: 310px;
    background: rgba(177, 212, 255, 0.5);
    border-radius: 10px;
    margin-left: 16%;
    /*z-index: 1;*/
}

.mobile-card-des-content {
    width: 70%;
    height: 200px;
    margin-left: 140px;
}

.title-sign-after {
    width: 110px;
    height: 110px;
}

.title-logo-core {
    width: 250px;
    margin-bottom: 30px;
    margin-top: 20px;
    /*height: 90px;*/
    /*margin-right: 15px;*/
    /*margin-bottom: 25px;*/
}

.mobile-title-logo-core {
    width: 300px;
    margin-bottom: 30px;
    margin-top: 40px;
}

.title-logo-service {
    width: 250px;
    margin-bottom: 30px;
    margin-top: 20px;
}

.mobile-title-logo-service {
    width: 300px;
    margin-bottom: 30px;
    margin-top: 40px;
}

.title-content {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    line-height: 10px;
    width: 100%;
}

.mobile-title-content {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    line-height: 10px;
    width: 100%;
}

#home-core-title {
    color: #535151;
}

#home-service-title {
    color: #665454;
}

.level-title {
    text-align: center;
    padding-top: 20px;
    margin-bottom: 50px;
}

.mobile-level-title {
    text-align: center;
    padding-top: 20px;
    margin-bottom: 70px;
}

.mobile-service-layer {
    width: 100%;
    background: white;
}


.level-one-content {
    min-height: 667px;
    min-width: 1430px;
    width: 1430px;
    margin: auto;
    background-color: #ffffff;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.mobile-core-layer {
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.part-one {
    float: left;
    background-image: url("../picture/SoftwareCore.jpg");
    background-size: 100%;
    cursor: pointer;
}

.software-core {
    height: 667px;
    overflow: hidden;
}

.mobile-core {
    background-size: cover;
    height: 300px;
    margin: auto auto 30px;
    width: 92%;
    border-radius: 15px 15px 15px 15px;
    max-width: 1100px;
    overflow: hidden;
    box-shadow: 0 0 7px rgb(31, 31, 31);
}

#software-core {
    background-image: url("../picture/SoftwareCore.jpg");
}

#mobile-software-core {
    background-image: url("../picture/MobileSoftwareCore.jpg");
}

#dig-core {
    background-image: url("../picture/DigCore.jpg");
}

#mobile-dig-core {
    background-image: url("../picture/MobileDigCore.jpg");
}

#design-core {
    background-image: url("../picture/DesignCore.jpg");
}

#mobile-design-core {
    background-image: url("../picture/MobileDesignCore.jpg");
}

#we-chart-core {
    background-image: url("../picture/WeChartCore.jpg");
}

#mobile-we-chart-core {
    background-image: url("../picture/MobileWeChartCore.jpg");
}

.part-two {
    float: left;
    margin-left: 11px;
}

.tools {
    min-height: 650px;
    width: 20%;
    background-color: #8c8989;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    float: left;
}

.tool-logo {
    width: 80%;
    height: 100px;
    border-radius: 90px;
    margin: 12px 12px 15px 12px;
}

.software-content {
    background-color: #91843f;
    float: left;
    width: 80%;
    min-height: 650px;
}

.official-account {
    width: 100%;
    min-height: 325px;
    background-color: #5ae9e9;
    border-top-right-radius: 30px;
}

.design{
    width: 100%;
    min-height: 325px;
    background-color: #3e9b9b;
    border-bottom-right-radius: 30px;
}

.level-three {
    min-height: 100vh;
    min-width: 1450px;
    width: 100%;
    -webkit-transform: translate3d(0,0,0);
    background: url("../picture/HomeContact.jpg") no-repeat fixed center;
    background-size: cover;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    z-index: 5;
    position: relative;
}

.home-footer-setting {
    position: relative;
    z-index: 9;
}

.contact-us-card{
    margin: auto;
    text-align: center;
    height: 700px;
    width: 1430px;
    background: rgba(0, 0, 0, 0.3);
}

.contact-us-card-contact{
    margin: auto;
    text-align: center;
    height: 28vw;
    width: 50vw;
    background: rgba(0, 0, 0, 0.3);
}

.contact-us-card-title {
    text-align: center;
    margin-bottom: 80px;
    padding-top: 30px;
}

.contact-us-card-title-contact {
    text-align: left;
    margin-left: 3vw;
    margin-bottom: 4vw;
    padding-top: 1vw;
}

#contact-card-cn {
    margin: auto;
    padding-top: 10px;
    padding-bottom: 5px;
    font-size: 30px;
    width: 140px;
    color: white;
    border-bottom: 1px solid white;
}

#contact-card-cn-contact {
    padding-bottom: 0.5vw;
    font-size: 1.5vw;
    width: 9.5vw;
    color: white;
    border-bottom: 1px solid white;
}

#contact-card-en {
    margin: auto;
    padding-bottom: 10px;
    font-size: 30px;
    font-weight: bold;
    width: 200px;
    color: #5ac2fa;
}

#contact-card-en-contact {
    padding-bottom: 0.1vw;
    font-size: 1.5vw;
    font-weight: bold;
    width: 25vw;
    color: #5ac2fa;
}

.contact-card-des {
    color: white;
}

.contact-card-des-contact {
    color: white;
    font-size: 0.7vw;
}

.contact-us-text-md {
    width: 100%;
    color: white;
    font-size: 20px;
    margin-left: 10%;
    margin-right: 5%;
}

.contact-us-text-md-contact {
    width: 100%;
    color: white;
    font-size: 1vw;
    margin-left: 3vw;
    margin-right: 5%;
}

.contact-us-text-name {
    margin-left: 10%;
    width: 40%;
    color: white;
    font-size: 1vw;
}

.contact-us-text-name-contact {
    margin-left: 3vw;
    width: 40% ;
    color: white;
    font-size: 1vw;
}

.contact-us-text-ct {
    width: 40%;
    margin-right: 5%;
    margin-left: 5%;
    color: white;
    font-size: 20px;

}

.contact-us-text-ct-contact {
    width: 40%;
    margin-left: 4.5vw;
    color: white;
    font-size: 1vw;

}

.contact-card-table {
    margin: auto;
    width: 1430px;
    display: flex;
    flex-wrap: wrap;
}

.contact-card-table-contact {
    /*margin: auto;*/
    width: 50vw;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
}

.contact-us-input {
    color: white;
    font-size: 20px;
    padding-bottom: 20px;
    margin-bottom: 40px;
    border-bottom: 1px solid white;
}

.contact-us-input-contact {
    color: white;
    font-size: 0.9vw;
    padding-bottom: 1vw;
    margin-bottom: 2vw;
    border-bottom: 0.1vw solid white;
}

.contact-submit {
    background: rgba(0, 0, 0, 0);
    color: #5ab5fa;
    font-size: 25px;
    margin-top: 50px;
    border: 0;
    cursor: pointer;
    outline: none;
}

.contact-submit-contact {
    background: rgba(0, 0, 0, 0);
    color: #5ab5fa;
    font-size: 1vw;
    font-weight: bold;
    float: left;
    margin-left: 3vw;
    border: 0;
    cursor: pointer;
    outline: none;
}

.ant-card-head {
    height: 150px;
    border-color: rgba(255, 255, 255, 0);
}

.core-bk {
    transform: scale(1);
    display: inline-block;
}

.core-bk-enter {
    opacity: 0;
    transform: translateY(0%) scale(1);
}

.core-bk-enter-active {
    opacity: 1;
    transform: translateY(0%) scale(1);
    transition: all 500ms ease-out;
}

.core-bk-exit {
    opacity: 0.1;
    transform: translateY(0%) scale(1);
}

.core-bk-exit-active {
    opacity: 1;
    transform: translateY(0%) scale(1);
    transition: all 300ms ease-in;
}

.dig-level {
    background-image: url("../picture/DigCore.jpg");
    background-size: auto 425px;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    cursor: pointer;
}

.dig-core {
    height: 425px;
    overflow: hidden;
}

.design-level {
    background-image: url("../picture/DesignCore.jpg");
    background-size: 408px 230px;
    float: left;
    margin-right: 3px;
    cursor: pointer;
}

.we-chart-level {
    background-image: url("../picture/WeChartCore.jpg");
    background-size: 100%;
    margin-left: 3px;
    float: left;
    cursor: pointer;
}

.we-chart-core {
    height: 230px;
    overflow: hidden;
}

.design-core {
    height: 230px;
    width: 408px;
    overflow: hidden;
}

#add-service {
    margin-top: 12px;
}
