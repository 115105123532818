@import '~antd/dist/antd.css';

.ant-popover-content p {
    margin: 0;
}

.position {
    width: 30px;
    height: 30px;
    background: #f6175e;
    line-height: 20px;
    text-align: center;
    border-radius: 60px 60px 60px 60px;
    border:3px #3e9b9b solid;
}

.sign {
    vertical-align: middle;
    font-size: 20px;
}

.map-tools {
    min-width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 1;
}

.mobile-map-tools {
    padding: 40px 6% 40px 6%;
    /*min-width: 1450px;*/
    width: 100%;
    height: 600px;
    position: relative;
}

.mobile-map-wid {
    border-radius: 900px 900px 900px 900px !important;
}

.map-target {
    width: 40px;
    height: 40px;
    font-size: 30px;
    color: #90cbe2;
}

.mobile-map-target {
    width: 50px;
    height: 50px;
    font-size: 50px;
    color: #90cbe2;
}

.mobile-map-target:hover {
    color: #47bfff;
}

.map-target:hover {
    color: #47bfff;
}

.top-page {
    background-image: url("../picture/CoOp.jpg");
    background-size: 100%;
    background-position: center;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    width: 80vw;
    height: 35vw;
    z-index: 2;
    position: relative;
    margin: 80px auto auto;
}

.mobile-top-page {
    background-image: url("../picture/CoOp.jpg");
    background-size: 100% 100%;
    background-position: center;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    width: 100%;
    height: 600px;
    position: relative;
    margin: auto;
}

.co-op {
    float: left;
    font-size: 3vw;
    font-weight: bold;
}

.mobile-co-op {
    float: left;
    font-size: 60px;
    font-weight: bold;
}

.top-page-des {
    height: 20vw;
    padding-top: 4vw;
}

.mobile-top-page-des {
    width: 100%;
    height: 200px;
    padding-top: 70px;
}

.co-op-line {
    width: 40vw;
}

.mobile-co-op-line {
    width: 100%;
}

.line-border {
    width: 40vw;
}

.mobile-line-border {
    width: 100%;
}

.co-op-contact {
    width: 80vw;
    height: 12vw;
    float: bottom;
}

.mobile-co-op-contact {
    width: 100%;
    height: 200px;
    float: bottom;
}

.co-op-contact-tag {
    text-align: center;
    width: 20vw;
    height: 12vw;
    float: left;
}

.mobile-co-op-contact-tag {
    padding-top: 90px;
    text-align: center;
    width: 25%;
    height: 200px;
    float: left;
}

.mobile-contact-card-click {
    color: #ffffff;
    font-size: 26px;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 80px;
    height: 80px;
    font-weight: bold;
}

.co-op-contact-tag-bk {
    margin: 0.5vw auto auto;
    width: 3vw;
    height: 3vw;
    border-radius: 3vw 3vw 3vw 3vw;
}

.mobile-co-op-contact-tag-bk {
    margin: 2px auto auto;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    box-shadow: 0 0 6px rgba(38, 38, 38, 0.9);
}

.co-op-border {
    height: 1vw;
    width: 5vw;
}

.mobile-co-op-border {
    height: 10px;
    width: 120px;
}

.contact-us-page {
    width: 100%;
    overflow: hidden;
}

.mobile-contact-us-page {
    /*min-width: 1450px;*/
    background: #eeeeee;
    width: 100%;
}

.bottom-page-right, .bottom-page-right-fix {
    width: 50vw;
    /*min-width: 715px;*/
    height: 28vw;
    z-index: 3;
    position: relative;
    float: right;
    overflow: hidden;
    margin-left: 50%;
}

.bottom-page-right-fix {
    /*min-width: 715px;*/
    position: fixed;
    bottom: 70px;
}

.bottom-page-left, .bottom-page-left-fix {
    background-image: url("../picture/JoinUs.jpg");
    background-size: 100%;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    background-position: center;
    /*min-width: 715px;*/
    width: 50vw;
    height: 28vw;
    overflow: hidden;
    float: left;
    z-index: 4;
    position: relative;
}

.mobile-join-us {
    /*background-image: url("../picture/JoinUs.jpg");*/
    /*background-size: 100% 100%;*/
    /*image-rendering: -moz-crisp-edges;*/
    /*image-rendering: -o-crisp-edges;*/
    /*image-rendering: -webkit-optimize-contrast;*/
    /*image-rendering: crisp-edges;*/
    /*-ms-interpolation-mode: nearest-neighbor;*/
    /*background-position: center;*/
    margin: 30px auto auto;
    /*min-width: 715px;*/
    width: 100%;
    height: 1090px;
    position: relative;
}

.join-us-title {
    height: 6vw;
    width: 50vw;
    /*background: #ce9595;*/
    margin-bottom: 1vw;
}

.mobile-join-us-title {
    background-image: url("../picture/JoinUsM.jpg");
    background-size: 100% 100%;
    height: 280px;
    width: 100%;
    text-align: center;
    /*background: #ce9595;*/
    /*margin-bottom: 1vw;*/
}

.join-us-info {
    height: 18vw;
    width: 50vw;
    /*background: #cebb95;*/
}

.mobile-join-us-info {
    /*height: 500px;*/
    background: white;
    padding-top: 60px;
    height: 790px;
    width: 100%;
}

.join-us-job {
    width: 50vw;
    /*background: #5e8760;*/
    height: 9vw;
    padding-left: 2vw;
}

.mobile-join-us-job {
    background: #ececec;
    padding-top: 50px;
    width: 90%;
    margin: 0 auto 50px auto;
    height: 300px;
    padding-left: 50px;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.join-us-job-img {
    height: 1.5vw;
    width: 1.5vw;
    float: left;
}

.mobile-join-us-job-img {
    height: 40px;
    width: 40px;
    float: left;
}

.join-us-job-title {
    float: left;
    font-size: 1.2vw;
    padding-left: 1vw;
    line-height: 1.3vw;
    font-weight: 500;
    color: #13347d;
}

.mobile-join-us-job-title {
    float: left;
    font-size: 34px;
    font-weight: bold;
    padding-left: 1vw;
    line-height: 34px;
    color: #13347d;
}

.join-us-job-content {
    color: #e5e5e5;
    font-size: 0.85vw;
}

.mobile-join-us-job-content {
    width: 95%;
    color: #212121;
    font-size: 32px;
    padding-bottom: 15px;
}

.join-us-cn {
    font-size: 2vw;
    line-height: 2vw;
    margin-left: 2vw;
    margin-top: 1.6vw;
    font-weight: bold;
    /*font-style: italic;*/
    color: #88c6ff;
}

.mobile-join-us-cn {
    font-size: 50px;
    line-height: 50px;
    font-weight: bold;
    /*font-style: italic;*/
    padding-top: 70px;
    color: #ffffff;
}

.join-us-en {
    font-size: 2.5vw;
    margin-left: 2vw;
    line-height: 3.5vw;
    font-weight: bold;
    color: #13347d;
    width: 17vw;
    border-bottom: 0.1vw solid #88c6ff;
}

.mobile-join-us-en {
    font-size: 50px;
    letter-spacing: 2px;
    font-weight: bold;
    color: #c8e7ff;
    width: 500px;
    margin: 10px auto auto;
    /*border-bottom: 4px solid #88c6ff;*/
}

.bottom-page-left-fix {
    /*min-width: 715px;*/
    position: fixed;
    bottom: 70px;
}

.empty-layer, .empty-layer-bottom, .empty-layer-top {
    height: 100vh;
    width: 100%;
    position: relative;
    z-index: -1;
}

.empty-layer-bottom {
    height: calc(28vw + 70px);
}

.empty-layer-top {
    height: 50vh;
}

.contact-footer-logo {
    float: left;
    height: 60px;
    width: 120px;
    margin-top: 5px;
    margin-bottom: 5px;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.contact-footer-legal {
    /*float: left;*/
    height: 70px;
    font-size: 0.7vw;
    margin-left: 20px;
    /*text-align: left;*/
    line-height: 70px;
    color: #cecece;
}

.contact-footer-contact {
    color: #dbdbdb;
    padding-left: 10px;
    margin-top: 10px;
    border-left: 2px solid #afadad;
    float: right;
    height: 50px;
    margin-right: 25px;
    font-size: 14px;
    text-align: left;
    line-height: 25px;
}

.contact-footer {
    text-align: center;
    position: relative;
    color: white;
    /*min-width: 100vw;*/
    height: 70px;
    width: 100%;
    z-index: 100;
    background: #2a2a2a;
    bottom: 0;
    margin-bottom: 0;
}