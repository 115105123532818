@import '~antd/dist/antd.css';

/*滚动条*/
::-webkit-scrollbar-track {
    -webkit-box-shadow:inset 0 0 6px rgba(68, 67, 67, 0.3);
    /*border-radius:10px;*/
    background-color: #807f7f;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: rgba(255, 255, 255, 0.2);
}

::-webkit-scrollbar-thumb {
    border-radius:10px;
    -webkit-box-shadow:inset 0 0 6px rgba(0, 0, 0, 0.5);
    background-color: #6a6a6a;
}

.home-side-layout {
    width: 100vw;
    background-color: #3e9b9b;
}

.ju-gu-logo {
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.ju-gu-logo-mobile {
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    position: absolute;
    z-index: 99;
}

.home-page-slider {
    width: 30%;
}

.side-menu {
    min-width: 50px;
    max-width: 250px;
    height: 100vh;
    position: fixed;
    border: 0;
    z-index: 999;
}

.slider-button {
    width: 100%;
    height: 100%;
    background-color: #545961;
    outline: none;
    opacity: 0.7;
    box-shadow: 0 0 0 #ffffff;
    /*border-color: #9e1d4a;*/
    /*border-width: 3px;*/
    /*margin-bottom: 0;*/
    border: 0;
    border-radius: 0;
}

.slider-button:hover {
    background-color: #468bf1;
}

.side-menu-header {
    padding-left: 0;
    height: 60px;
    background: #545961;
    padding-right: 0;
    margin-bottom: 10px;
}

.home-sub-menu-item > .ant-menu {
    padding-left: 10px !important;
    padding-bottom: 10px !important;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
}

.home-menu-item {
    padding-left: 10px !important;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
    font-size: 14px;
}

.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon{
    font-size: 20px !important;
}

.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon{
    font-size: 20px !important;
}

.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 0 calc(50% - 20px / 2) !important;
}

.ant-menu-inline-collapsed > .ant-menu-item {
    padding: 0 calc(50% - 20px / 2) !important;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #3b4653 !important;
}

.home-sub-menu-item  > .ant-menu-submenu-title {
    padding-left: 10px !important;
    /*margin-bottom: 10px !important;*/
    /*margin-top: 10px !important;*/
    /*background-color: #a1924b !important;*/
    font-size: 14px;
    /*margin: 0 0 0 0 !important;*/
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
    background-color: rgba(30, 67, 130, 0.5);
}

.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active {
    color: white;
}

.we-content {
    color: #dde7e7;
    text-align: center;
}

.we-logo {
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    height: 170px;
    width: 170px;
    margin: 15px 40px 15px 40px;
}

.we-logo-inside {
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    height: 160px;
    overflow: hidden;
    background: rgba(30, 67, 130, 0.2);
}

.we-chart {
    position: fixed;
    bottom: 0;
    width: 250px;
    height: 240px;
    z-index: -1;
}

.we-chart-bottom {
    position: fixed;
    bottom: 0;
    width: 80px;
    height: 80px;
}

.side-language-bottom {
    position: fixed;
    bottom: 80px;
    width: 80px;
    height: 80px;
}

.side-language-content {
    background-color: rgba(0, 0, 0, 0);
    width: 65px;
}

.ant-popover-inner-content {
    background-color: rgba(59, 58, 58, 0.5);
    padding: 0;
    border: 0;
}

.ant-popover-arrow {
    border-bottom-color: transparent !important;
    border-left-color: transparent !important;
}

.ant-popover-content {
    background-color: rgba(0, 0, 0, 0) !important;
}

.home-page-collapsed {
    float: left;
    padding-left: 80px;
    width: calc(100% - 8px);
}

.home-page-not-collapsed {
    float: left;
    padding-left: 250px;
    width: calc(100% - 8px);
}

.side-header-collapsed {
    width: calc(100vw - 80px);
    min-width: 1430px;
    height: 80px;
    padding-left: 15px;
    padding-right: 0;
    color: rgb(1, 9, 16);
    background: rgb(229, 147, 238);
    text-align: center;
    position: fixed;
    z-index: 1;
}

.side-header-not-collapsed {
    width: calc(100vw - 250px);
    min-width: 1430px;
    height: 80px;
    padding-left: 15px;
    padding-right: 0;
    color: rgb(1, 9, 16);
    background: rgb(229, 147, 238);
    text-align: center;
    position: fixed;
    z-index: 1;
}

.top-logo-sign {
    height: 55px;
}

.top-header-right {
    float: right;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: auto;
    height: 100%;
}

.login-box {
    margin-right: 20px;
    font-size: 15px;
    color: rgba(255, 255, 255, 0.65);
    cursor: pointer;
}

.language-box {
    width: 60px;
    margin-right: 8px;
    height: 100%;
    position: relative;
    background-color: #656b74;
    border-radius: 0;
    border: 0;
}

.language-box-clean {
    width: 60px;
    margin-right: 8px;
    height: 100%;
    position: relative;
    background-color: rgba(101, 107, 116, 0.5);
    border-radius: 0;
    border: 0;
}

.language-box:hover {
    background-color: #656b74;
}

.language-box:focus {
    background-color: #656b74;
}

.language-box-clean:hover {
    background-color: rgba(101, 107, 116, 0.5);
}

.language-box-clean:focus {
    background-color: rgba(101, 107, 116, 0.5);
}

.language-item {
    text-align: center;
    background-color: rgba(30, 67, 130, 0.5);
    color: white;
    margin: 0 !important;
}

.language-item:hover {
    background-color: rgba(101, 107, 116, 0.7);
    color: white;
}

.language-item:focus {
    background-color: rgba(101, 107, 116, 0.7);
    color: white;
}

.ant-popover-inner {
    background: rgba(0, 0, 0, 0);
}

html {
    overflow-y: overlay;
}

.page-layout {
    background-color: rgba(255, 255, 255, 0.6);
}

button[ant-click-animating-without-extra-node]:after {
    border: 0 none;
    opacity: 0;
    animation:none 0 ease 0 1 normal;
}

.top-header {
    background-color: #545961;
    /*background-color: rgba(255, 255, 255, 0);*/
    height: 80px;
    width: 100%;
    /*min-width: 1430px;*/
    min-width: 960px;
    padding-left: 0;
    padding-right: 0;
    z-index: 99;
    position: fixed;
}

.top-header-clean {
    background-color: rgba(84, 89, 97, 0.5) !important;
    height: 80px;
    width: 100%;
    /*min-width: 1430px;*/
    padding-left: 0;
    padding-right: 0;
    z-index: 99;
    position: fixed;
}

.logo-header {
    height: 80px;
    width: 140px;
    /*background-color: #3cb492;*/
    /*background-color: rgba(60, 180, 146, 0.5);*/
    float: left;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    cursor: pointer;
}

.page-top-menu {
    background: rgba(0, 0, 0, 0) !important;
    font-size: 15px;
    text-align: center;
    width: 900px;
    margin: auto !important;
}


.narrow-top-header {
    z-index: 99;
    height: 50px;
    position: fixed;
    width: 100%;
    min-width: 1430px;
    background-color: rgb(84, 89, 97);
}

.mobile-top-header {
    /*min-width: 1450px;*/
    width: 100%;
    height: 8vh;
    background: #eae7e7;
    position: relative;
}

.ant-menu-sub.ant-menu-inline {
    background: rgba(22, 81, 153, 0.3);
}

.narrow-menu-top {
    height: 50px;
    background-color: #656b74;
    color: white;
    border: 0;
}
.narrow-menu-top:hover {
    height: 50px;
    background-color: #656b74;
    color: white;
    border: 0;
}

.narrow-menu-top:focus {
    height: 50px;
    background-color: #656b74;
    color: white;
    border: 0;
}

.mobile-menu-top {
    /*background: #182d45;*/
    background: rgba(22, 81, 153, 0.5) !important;
    height: 8vh;
    width: 100%;
    border: 0;
}

.narrow-top-menu {
    background-color: rgba(47, 112, 172, 0.8) !important;
    font-size: 15px !important;
    width: 70vw !important;
    height: 100%;
    float: left;
}

.mobile-top-menu {
    width: 100%;
    color: #424242;
    background: rgba(217, 217, 217, 0.95);
    line-height: 6vh;
    font-size: 2.5vh;
    position: fixed;
}

.mobile-top-menu:hover {
    color: #424242;
}

.mobile-sub-menu-item >.ant-menu-submenu-title{
    font-weight: bold;
    font-size: 2.2vh;
    height: 6vh !important;
    line-height: 6vh !important;
    padding-right: 0;
    color: #494949 !important;
}

.mobile-sub-menu-item:hover >.ant-menu-submenu-title >.ant-menu-submenu-arrow {
    font-size: 2.2vh;
    color: #424242 !important;
}

.mobile-sub-menu-item:focus >.ant-menu-submenu-title {
    color: #424242 !important;
}

.mobile-sub-menu-sub-item {
    color: #494949;
    font-weight: bold;
    font-size: 2.2vh;
    height: 6vh !important;
    line-height: 6vh !important;
}

.mobile-sub-menu-sub-item:hover {
    color: #424242 !important;
}

.mobile-sub-menu-sub-item:focus {
    color: #424242 !important;
}

.mobile-menu-item {
    height: 6vh !important;
}

.page-top-menu-clean {
    background-color: rgba(84, 89, 97, 0) !important;
    font-size: 15px;
    text-align: center;
    width: 900px;
    margin: auto !important;
}

.page-sub-menu-item {
    height: 80px;
    line-height: 80px;
}

.narrow-sub-menu-item {
    height: 40px;
    line-height: 40px;
    margin: 4px 0 8px !important;
}

.page-menu-item {
    height: 80px;
    line-height: 80px;
}

.page-sub-menu-sub-item {
    width: 14vw;
    /*text-align: left;*/
    /*float: left;*/
    background-color: rgba(65, 99, 158, 0.3);
    margin: 0 0 0 0 !important;
}

.ant-menu-sub.ant-menu-vertical {
    background-color: rgba(21, 21, 21, 0.7) !important;
    margin: 0 0 0 0 !important;
    padding: 0 0 0 0 !important;
    /*background-size: 100% !important;*/
    top: 0 !important;
}

.App-logo {
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 5s linear;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
