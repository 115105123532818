.service-page {
    /*width: 100%;*/
    /*min-height: 100vh;*/
    background: #e3e3e3;
}

.item-app-general-title-top {
    position: fixed;
    left: 0;
    width: 130px;
    height: 60px;
    text-align: center;
    cursor: pointer;
}

.item-app-general-title-top:hover {
    background: rgba(42, 80, 121, 0.8) !important;
}

.item-app-general-title-text-top {
    margin: auto;
    font-family: "微软雅黑", serif;
    font-size: 16px;
    line-height: 60px;
    font-weight: bold;
    color: #e3e3e3;
}

.service-contact-card {
    height: 800px;
    background-image: url("./picture/ServiceWeb.jpg");
    background-attachment: fixed;
    background-size: cover;
    width: 100% 100%;
    min-width: 1430px;
    margin: 100px auto auto;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.mobile-service-items-menu-hidden {
    position: fixed;
    width: 100%;
    height: 120px;
    top: 0;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
    background: #ffffff;
    z-index: -99;
    visibility: hidden;
}

.mobile-service-items-menu-visible {
    position: fixed;
    width: 100%;
    height: 120px;
    top: 0;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
    background: #ffffff;
    z-index: 999;
    visibility: visible;
    animation: menu-ani 300ms;
}

@keyframes menu-ani {
    from {
        transform: translate(0, -100%);
    }
    to {
        transform: translate(0, 0);
    }
}

.mobile-service-items-menu-item {
    font-size: 35px;
    text-align: center;
    line-height: 100px;
    color: #393939;
    width: 20%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    border-top: 6px solid;
    height: 100px;
    margin-top: 15px;
    float: left;
}

.service-contact-card-empty {
    height: 50px;
}

/*.service-cores-title {*/
/*    font-family: "微软雅黑";*/
/*    font-size: 32px;*/
/*    text-align: center;*/
/*    background: #7d57c4;*/
/*}*/

.service-cores {
    width: 100%;
    /*height: 120%;*/
    min-width: 1450px;
    max-width: 1920px;
    margin: auto;
    /*min-height: 1000px;*/
    background: #ffffff;
}

.cores-contents {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 95%;
    height: 1200px;
    margin: 50px auto;
    overflow: hidden;
    /*background-color: #e23d3d;*/
}

.core-size-software {
    position: relative;
    width: 50%;
    height: 50%;
    /*background-color: #dedede;*/
    cursor: pointer;
}

.core-size-digital {
    position: relative;
    width: 50%;
    height: 50%;
    /*background-color: #b1adad;*/
    cursor: pointer;
}

.core-size-design {
    position: relative;
    width: 50%;
    height: 50%;
    /*background-color: #bfbebe;*/
    cursor: pointer;
}

.core-size-chart {
    position: relative;
    width: 50%;
    height: 50%;
    /*background-color: #d7d4d4;*/
    cursor: pointer;
}

.core-size-software-e {
    position: relative;
    width: 100%;
    height: 100%;
    /*min-height: 500px;*/
    /*background-color: #dedede;*/
    cursor: pointer;
}

.core-size-digital-e {
    position: relative;
    width: 100%;
    height: 100%;
    /*background-color: #dedede;*/
    cursor: pointer;
}
.core-size-design-e {
    position: relative;
    width: 100%;
    height: 100%;
    /*background-color: #dedede;*/
    cursor: pointer;
}

.core-size-chart-e {
    position: relative;
    width: 100%;
    height: 100%;
    /*background-color: #dedede;*/
    cursor: pointer;
}

.core-enter-software-e {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
    /*background-color: #dedede;*/
}

.core-enter-digital-e {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
    /*background-color: #b1adad;*/
}

.core-enter-design-e {
    position: relative;
    z-index: 3;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
    /*background-color: #bfbebe;*/
}

.core-enter-chart-e {
    position: relative;
    z-index: 4;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
    /*background-color: #d7d4d4;*/
}

.core-size-software-angle {
    position:absolute;
    float: right;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border-right: 50px solid rgb(149, 184, 203);
    border-top: 50px solid transparent;
}

.core-size-digital-angle {
    width: 0;
    height: 0;
    border-bottom: 50px solid rgb(65, 116, 172);
    /*border-top: 5vh solid transparent;*/
    border-right: 50px solid transparent;
    position: absolute;
    float: left;
    bottom: 0;
    left: 0;
}

.core-size-design-angle {
    width: 0;
    height: 0;
    border-bottom: 50px solid transparent;
    border-top: 50px solid rgb(65, 116, 172);
    border-left: 50px solid transparent;
    position: absolute;
    float: right;
    top: 0;
    right: 0;
}

.core-size-we-chart-angle {
    width: 0;
    height: 0;
    border-bottom: 50px solid transparent;
    border-left: 50px solid rgb(149, 184, 203);
    position: absolute;
    float: left;
    top: 0;
    left: 0;
}

.service-core {
    width: 55%;
    height: 350px;
    overflow: hidden;
    /*margin-right: 1vh;*/
    /*margin-left: 1vh;*/
}

.service-image {
    object-fit: cover;
    overflow: hidden;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

#right-position {
    float: right;
}

#left-position {
    float: left;
}

.service-core-left-bg {
    position: absolute;
    height: 350px;
    z-index: 98;
    right: 45.2%;
    border-top: 175px solid  transparent;
    border-left: 60px solid transparent;
    border-right:  60px solid #dbdada;
    border-bottom:  175px solid  transparent;
}

.service-core-right-bg {
    position: absolute;
    height: 350px;
    z-index: 98;
    left: 45.2%;
    border-top: 175px solid  transparent;
    border-left:  60px solid #dbdada;
    border-right:  60px solid transparent;
    border-bottom:  175px solid  transparent;
}

.core-title {
    z-index: 2;
    padding: 35px 0;
    width: 47.5vw;
    height: 120px;
    /*min-width: 900px;*/
    text-align: center;
    font-family: "微软雅黑";
    margin: auto;
    font-size: 26px;
    font-weight: bold;
    background-color: #ffffff;
}

.service-core-image {
    width: 50vh;
    /*height: 50%;*/
    /*margin: 50px;*/
    transform: scale(1);
    display: inline-block;
}

.service-core-image-enter {
    transform: translateY(0%) scale(1);
}

.service-core-image-enter-active {
    transform: translateY(0%) scale(1.2);
    transition: all 400ms;
}

.service-core-image-exit {
    transform: translateY(0%) scale(1.2);
}

.service-core-image-exit-active {
    transform: translateY(0%) scale(1);
    transition: all 400ms;
}

.core-single-content {
    width: 45%;
    height: 350px;
    padding: 20px;
    /*word-break: break-all;*/
    font-family: "Arial", "Microsoft YaHei", "黑体", "宋体", sans-serif;
    font-weight: 500;
    line-height: 3vh;
    float: left;
    background-color: #dbdada;
}

.core-statement {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
    font-family: "微软雅黑";
    font-size: 24px;
    line-height: 40px;
    font-weight: bold;
}

.core-tag {
    width: 100%;
    height: 90px;
    font-family: "微软雅黑";
    line-height: 30px;
    font-size: 18px;
    /*letter-spacing: 0.2rem;*/
}

.line-tags {
    height: 50px;
    margin-top: 5px;
    text-align: center;
}

.line-tag {
    margin: 0.5vh 0.5vh 0.5vh 0.5vh;
    font-weight: bold;
}

.line-tag:focus {
    color: #2f527d;
    border-color: #2f527d;
}

.line-tag:hover {
    color: #2f527d;
    border-color: #2f527d;
}

.service-core-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    /*z-index: 98;*/
    width: 95%;
    height: 450px;
    /*min-height: 280px;*/
    background: white;
}

.mobile-service-cores {
    z-index: 1;
    /*padding-top: 100px;*/
    width: 100%;
    height: 1140px;
    /*background: #5ff1a6;*/
}

.mobile-service-cores-empty {
    width: 100%;
    height: 50px;
    background: rgba(171, 172, 173, 0.2);
}

.mobile-service-cores-empty-narrow {
    width: 100%;
    height: 30px;
    background: rgba(171, 172, 173, 0.2);
}

.mobile-service-cores-title {
    padding-top: 100px;
    font-family: "微软雅黑", serif;
    font-size: 60px;
    text-align: center;
    font-weight: bold;
    color: #556272;
}

.mobile-service-cores-container{
    /*padding-top: 100px;*/
    /*width: 100%;*/
    height: 1140px;
}
.tab_title_wrap{
    display: block;
    overflow-x: scroll;
    height: 160px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    /*margin: 40px auto 0;*/
    /*width: 100%;*/
    /*height: 160px;*/
    /*overflow-x: scroll;*/
}
.tab_title_wrap-box {
    float: left;
    width: 160%;
    height: 130px;
}
.tab_title{
    margin: 30px 40px;
    /*width: 25%;*/
    height: 100px;
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    line-height: 94px;
    color: #000000;
    background-color: #ffffff;
    display: inline-block;
}
.active{
    font-size: 36px;
    color: #2e64a1;
    background-color: #ffffff;
    border-top: 8px solid rgba(71, 130, 217, 0.8);
}
.tab_item_wrap{
    width: 100%;
    height: 850px;
}
.tab_item{
    margin: 0 auto;
    width: 100%;
    height: 850px;
    /*text-align: center;*/
    /*color: #FFF;*/
    display: none;
}
/*.tab_item:nth-of-type(1){*/
/*    background-color: #FF660E;*/
/*}*/
/*.tab_item:nth-of-type(2){*/
/*    background-color: #FF0E52;*/
/*}*/
/*.tab_item:nth-of-type(3){*/
/*    background-color: #07D739;*/
/*}*/
.show{
    display: block;
}

.mobile-service-cores-nav {
    display: block;
    overflow-x: scroll;
    margin: 45px auto;
    /*width: 100%;*/
    height: 120px;
    font-family: "微软雅黑";
}

.mobile-service-cores-nav-box {
    float: left;
    width: 180%;
    height: 80px;
}

.mobile-service-cores-nav-list {
    width: 25%;
    box-sizing: border-box;
    height: 80px;
    float: left;
    text-align: center;
    align-items: center;
    border-right: 1px solid rgba(0,0,25,.1);
    font-size: 30px;
    font-weight: bold;
}

.mobile-service-cores-nav-list:hover {
    background: #47bfff;
}

.mobile-service-cores-list {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 70%;
    text-align: left;
}


.mobile-service-cores-box {
    margin: 0 auto;
    width: 100%;
    height: 850px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    /*background: rgba(0, 0, 0, 0.5);*/
}

.mobile-service-cores-software {
    display: block;
    width: 100%;
    height: 600px;
    /*background-image: url("../../picture/ServiceCoreSoftware.jpg");*/
    /*background-size: 100% 100%;*/
    /*background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url("../../picture/ServiceCoreSoftware.jpg");*/
}

.mobile-service-cores-digital {
    display: block;
    width: 100%;
    height: 600px;
    /*background-image: url("../../picture/ServiceCoreDigital.jpg");*/
    /*background-size: 100% 100%;*/
}

.mobile-service-cores-marketing {
    width: 100%;
    height: 600px;
    /*background-image: url("../../picture/ServiceCoreMarketing.jpg");*/
    /*background-size: 100% 100%;*/
}

.mobile-service-cores-wechart {
    width: 100%;
    height: 600px;
    /*background-image: url("../../picture/ServiceCoreWechart.jpg");*/
    /*background-size: 100% 100%;*/
}

.mobile-service-cores-software-label {
    width: 100%;
    height: 250px;
    background-image: url("../../picture/ServiceWebM.jpg");
    background-size: 100% 100%;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.mobile-service-cores-digital-label {
    width: 100%;
    height: 250px;
    background-image: url("../../picture/ServiceDgM.jpg");
    background-size: 100% 100%;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.mobile-service-cores-marketing-label {
    width: 100%;
    height: 250px;
    background-image: url("../../picture/ServiceDegM.jpg");
    background-size: 100% 100%;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.mobile-service-cores-wechart-label {
    width: 100%;
    height: 250px;
    background-image: url("../../picture/ServiceWeM.jpg");
    background-size: 100% 100%;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.mobile-service-cores-content {
    float: left;
    padding-left: 80px;
    width: 70%;
    height: 600px;
    /*background: rgba(0, 0, 0, 0.5);*/
}

.mobile-service-cores-content-title{
    padding-top: 10%;
    padding-bottom: 3%;
    font-size: 45px;
    font-weight: bolder;
    color: #b2ccec;
}

.mobile-service-cores-content-subtitle {
    padding-top: 100px;
    padding-bottom: 20px;
    font-size: 40px;
    font-weight: bold;
    color: #404040;
}

.mobile-service-cores-content-txt {
    letter-spacing: 1px;
    margin-top: 130px;
    width: 90%;
    height: 50%;
    font-size: 34px;
    color: #232323;
}

.mobile-service-cores-tags {
    float: left;
    width: 25%;
    /*height: 100%;*/
    border-left: 0;
    /*background: rgba(0, 0, 0, 0.5);*/
}

.mobile-line-tags {
    display: flex;
    flex-direction: column;
    height: 600px;
    align-items: center;
    justify-content: center;
}

.mobile-line-tag {
    width: 95%;
    height: 90px;
    margin: 0 0.5vh 0.5vh 0;
    font-size: 32px;
    font-weight: bold;
    border: 1;
    background: rgba(232, 232, 232, 0.3);
}

.mobile-line-tag:hover {
    color: #b2ccec;
    border: 0;
    background: rgba(255, 255, 255, 0);
}