/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.banner {
  width: 100%;
  min-width: 1450px;
  height: 100vh;
  min-height: 700px;
}
.banner .page {
  width: 100%;
  min-width: 1450px;
  height: 100vh;
  min-height: 700px;
}
.banner .banner-wrapper {
  position: absolute;
  z-index: 10;
  width: 100%;
  min-width: 1450px;
  overflow: hidden;
  background: no-repeat center / cover;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.banner .banner-text-wrapper {
  position: absolute;
  left: 15%;
  top: 30%;
  color: #fff;
  text-align: right;
  font-family: "微软雅黑";
}
.banner .banner-text-wrapper h2 {
  font-size: 52px;
  font-weight: bold;
  font-family: "微软雅黑";
  white-space: nowrap;
  color: #e5e5e5;
  margin-bottom: 10px;
}
.banner .banner-text-wrapper h2 p {
  font-size: 45px;
  letter-spacing: 3px;
  color: #bfedff;
  display: inline-block;
  margin: 0;
}
.banner .banner-text-wrapper p {
  font-size: 26px;
  font-weight: bold;
  color: #ffffff;
  margin: 25px auto 24px;
}
.banner .banner-text-wrapper .line {
  width: 2px;
  height: 120px;
  position: absolute;
  background: #99A9BF;
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.03));
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.03));
  background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.03));
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.03));
  top: 16px;
  right: -25px;
}
.banner .banner-text-wrapper .start-button {
  margin-bottom: 24px;
  text-align: right;
}
.banner .banner-text-wrapper .start-button > a {
  display: inline-block;
  height: 40px;
  padding: 0 16px;
  font-weight: 500;
  border-radius: 2px;
  border: 1px solid #ffffff;
  text-decoration: none;
  color: #ffffff;
  font-size: 15px;
  background: transparent;
  transition: all .45s;
  text-align: center;
  line-height: 36px;
  margin-left: 8px;
}
.banner .banner-text-wrapper .start-button > a:hover {
  color: #fff;
  background: rgba(90, 90, 90, 0.6);
}
.banner .down {
  text-align: center;
  position: absolute;
  font-size: 40px;
  color: #ffffff;
  bottom: 10px;
  left: 50%;
  animation: upDownMove 1.2s ease-in-out infinite alternate;
}
@keyframes upDownMove {
  to {
    transform: translateY(10px);
  }
}
