.login-page-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    min-height: 670px;
    width: 100%;
    min-width: 1200px;
    background: #efefef;
}

.login-page {
    display: flex;
    width: 1000px;
    height: 540px;
    background: white;
}

.login-left {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 50%;
    background: #3775c1;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}

.login-right {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 50%;
    background: transparent;
}

.login-left-bg {
    width: 500px;
    position: absolute;
}

.login-left-ct {
    width: 74%;
    height: 86%;
    background: transparent;
}

.logo-wrap {
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    background: transparent;
}

.logo {
    height: 50px;
    width: auto;
    justify-content: center;
    display: flex;
    align-items: center;
    color: white;
}

.logo-img {
    width: 100%;
    height: 100%;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-crisp-edges;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    object-fit: contain;
}

.title-wrap {
    width: 100%;
    height: 80px;
    background: transparent;
    display: flex;
    align-items: center;
    font-size: 26px;
    font-weight: bold;
    letter-spacing: 4px;
    color: white;
}

.des-wrap {
    width: 100%;
    height: 156px;
    background: transparent;
    border-bottom: 2px solid rgba(255, 255, 255, 0.4);
    line-height: 36px;
    letter-spacing: 2px;
    font-size: 16px;
    color: #f3f3f3;
}

.login-right-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 76%;
    height: 60%;
    background: transparent;
}

.login-fun-title {
    width: 100%;
    height: 50px;
    background: transparent;
    display: flex;
}

.login-fun-title-txt {
    width: 50%;
    height: 100%;
    background: transparent;
    font-size: 24px;
    color: #5c5c5c;
    font-weight: bold;
    letter-spacing: 2px;
    display: flex;
    align-items: flex-end;
}

.login-fun-corner {
    width: 50%;
    height: 100%;
    background: transparent;
    font-size: 14px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.login-fun-input-wrap {
    height: auto;
    width: 100%;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.login-input {
    margin: 0 0 20px 0;
    width: calc(100% - 14px);
    height: 40px;
    padding-left: 10px;
    font-size: 16px;
    border: 1px solid #d7d7d7;
}

.login-input:focus {
    margin: 0 0 20px 0;
    padding-left: 10px;
    border: 1px solid #8aaed7;
    outline: none;
}

.login-btn {
    width: 100%;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 4px;
    text-indent: 4px;
    font-size: 18px;
    color: white;
    background: #3c80d4;
    margin-top: 30px;
    cursor: pointer;
    transition-property: background;
    -moz-transition-property: background; /* Firefox 4 */
    -webkit-transition-property:background; /* Safari and Chrome */
    -o-transition-property:background; /* Opera */
    transition-duration: 100ms;
    -moz-transition-duration: 100ms;
    -webkit-transition-duration: 100ms;
    -o-transition-duration: 100ms;
    transition-timing-function: linear, ease;
    -moz-transition-timing-function: linear, ease;
    -webkit-transition-timing-function: linear, ease;
    -o-transition-timing-function: linear, ease;
}

.login-btn:hover {
    background: #518cd5;
    transition-property: background;
    -moz-transition-property: background; /* Firefox 4 */
    -webkit-transition-property:background; /* Safari and Chrome */
    -o-transition-property:background; /* Opera */
    transition-duration: 100ms;
    -moz-transition-duration: 100ms;
    -webkit-transition-duration: 100ms;
    -o-transition-duration: 100ms;
    transition-timing-function: linear, ease;
    -moz-transition-timing-function: linear, ease;
    -webkit-transition-timing-function: linear, ease;
    -o-transition-timing-function: linear, ease;
}