.service-items {
  width: 100%;
  min-width: 1450px;
  //height: 100vh;
  //min-height: 100vh;
  background: #e3e3e3; /* fallback for old browsers */
}

.item-web-general {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 937px;
  margin: auto;
  background: url("./picture/ServiceWeb.jpg") fixed;
  background-size: 100% 100%;
  //background: #abbaab;  /* fallback for old browsers */
  //background: -webkit-linear-gradient(to right, #ffffff, #abbaab);  /* Chrome 10-25, Safari 5.1-6 */
  //background: linear-gradient(to right, #ffffff, #abbaab); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.item-web-general-layout {
  position: relative;
  z-index: 2;
  width: 95%;
  height: 100%;
  max-width: 1920px;
  margin: auto;
  text-align: center;
  //background: #ffffff;
}

.item-web-general-title {
  padding-top: 75px;
  //padding-bottom: 20px;
  font-family: "微软雅黑", serif;
  font-size: 36px;
  color: white;
  font-weight: bold;
}

.item-web-general-subtitle {
  font-family: "微软雅黑", serif;
  font-size: 19px;
  font-weight: bold;
  color: #b3b3b3;
}

.item-web-general-nav {
  display: flex;
  margin: 45px auto;
  width: 1023.3px;
  height: 50px;
  overflow: hidden;
  //border-bottom: 1px solid #999;
  font-family: "微软雅黑";
  font-size: 20px;
  //background-color: rgba(28, 58, 109, 0.7);
  .swiper-wrapper {
    display: table;
    width: 60%;
    height: 50px;
    padding: 0;
    //margin: auto;
    table-layout: fixed;
    border-bottom: 1px solid #999;
    font-family: "微软雅黑";
    font-size: 20px;
  }

  .swiper-slide {
    display: table-cell;
    width: 204.66px !important;
    height: 50px;
    text-align: center;
    vertical-align: middle;
    font-size: 20px;
    color: #bcd9ff;
    font-weight: bold;
  }

  .swiper-slide-active {
    color: #ffffff;
    font-size: 24px;
    border-bottom: 2px outset #ffffff;
    transition: all .4s ease;
    //background-color: #3779ec;
  }

  .swiper-slide-duplicate {
    opacity: 1 !important;
    position: fixed !important;
    background-color: #ffffff !important;
    width: 0 !important;
    height: 0 !important;
    overflow: hidden !important;
    z-index: -99 !important;
  }
}

// web-general-content
.item-web-general-list {
  position: relative;
  display: flex;
  flex-direction: column;
  //justify-content:center;
  //align-items:center;
  float: left;
  width: 100%;
  height: 70%;
  text-align: left;
  //background: #ffffff;
  .swiper-slide {
    display: table-cell;
    width: 80%;
    height: 100%;
    text-align: center;
    vertical-align: middle;
  }

  .swiper-slide-next {
    opacity: 0;
  }
}

.item-web-general-content {
  position: relative;
  display: flex;
  flex-direction: column;
  //justify-content:center;
  //align-items:center;
  float: left;
  width: 45%;
  height: 80%;
  margin-left: 0;
  padding-left: 50px;
  padding-top: 40px;
  text-align: left;
  background: rgba(0, 0, 0, 0.5);
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, rgba(229, 222, 222, 0.5)), color-stop(100%, rgba(0, 0, 0, 0.5)));
  background: -webkit-linear-gradient(-45deg, rgba(229, 222, 222, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%);
  background: -webkit-linear-gradient(315deg, rgba(229, 222, 222, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%);
  background: linear-gradient(135deg, rgba(229, 222, 222, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%);
}

.content-title {
  display: flex-cell;
  font-family: "微软雅黑", serif;
  font-size: 32px;
  font-weight: bold;
  color: #d5d5d5;
}

.content-point {
  display: flex-cell;
  padding-top: 20px;
  font-family: "微软雅黑", serif;
  font-size: 20px;
  font-weight: bold;
  color: #94c8ff;
}

.content-point-text {
  display: flex-cell;
  padding-top: 5px;
  padding-left: 43px;
  font-family: "微软雅黑", serif;
  font-size: 18px;
  color: #ffffff;
}

// 图片
// 策略定位
.strategy-image {
  z-index: auto;
  position: relative;
  float: left;
  width: 55%;
  height: 80%;
  margin-left: 0;
  background: url("./picture/strategy-image.jpg") center / 100% 100% no-repeat;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

// 视觉设计
.visual-image {
  z-index: auto;
  position: relative;
  float: left;
  width: 55%;
  height: 80%;
  margin-left: 0;
  background: url("./picture/visual-image.jpg") center / 100% 100% no-repeat;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

// 系统开发
.system-image {
  z-index: auto;
  position: relative;
  float: left;
  width: 55%;
  height: 80%;
  margin-left: 0;
  background: url("./picture/system-image.jpg") center / 100% 100% no-repeat;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

// 后台管理
.backstage-image {
  z-index: auto;
  position: relative;
  float: left;
  width: 55%;
  height: 80%;
  margin-left: 0;
  background: url("./picture/backstage-image.jpg") center / 100% 100% no-repeat;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

// 算法实现
.algorithm-image {
  z-index: auto;
  position: relative;
  float: left;
  width: 55%;
  height: 80%;
  margin-left: 0;
  background: url("./picture/algorithm-image.jpg") center / 100% 100% no-repeat;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

// 个性化App开发
.item-app-general {
  position: relative;
  z-index: 1;
  width: 100%;
  min-width: 1450px;
  height: 2000px;
  margin: auto;
  background: #e3e3e3;
  //background: #373B44;  /* fallback for old browsers */
  //background: -webkit-linear-gradient(to right, #4286f4, #373B44);  /* Chrome 10-25, Safari 5.1-6 */
  //background: linear-gradient(to right, #4286f4, #373B44); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.app-general-paper {;
  height: 2000px;
  width: 100%;
  margin: auto;
  background: white;
}

.item-app-title-des {
  padding-top: 75px;
  font-family: "微软雅黑", serif;
  font-size: 36px;
  text-align: center;
  font-weight: bold;
  color: rgb(85, 98, 114);
}

.item-app-title-des-sub {
  color: #999999;
  text-align: center;
  font-family: "微软雅黑", serif;
  font-size: 19px;
  font-weight: bold;
  margin: auto;
}

.item-app-general-content-one {
  position: relative;
  width: 85%;
  max-width: 1920px;
  height: 460px;
  margin: 120px auto 50px;
  //background: #9d7272;
  .app-image-one {
    position: relative;
    z-index: 2;
    width: 40%;
    margin-right: 8%;
    margin-left: 10%;
    height: 100%;
    float: left;
    //background: #9d7272;
    background: url("./picture/AppOne.png") no-repeat center;
    background-size: 600px 500px;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }

  .app-content-one {
    float: left;
    position: relative;
    //top: -70%;
    //left: 65%;
    z-index: 3;
    width: 30%;
    height: 100%;
    background: rgba(175, 203, 236, 0.9);
    //background: white;
  }

  .app-content-one-title {
    margin-left: 30px;
    font-family: "微软雅黑", serif;
    margin-top: 30px;
    font-size: 32px;
    font-weight: bold;
    color: rgb(101, 115, 134);
  }

  .app-content-one-subtitle {
    margin-left: 30px;
    font-family: "微软雅黑", serif;
    font-size: 19px;
    font-weight: bold;
    color: #9e9e9e;
  }

  .app-content-one-text {
    margin-left: 30px;
    margin-top: 20px;
    font-family: "微软雅黑", serif;
    font-size: 17px;
    color: rgb(255, 255, 255);
  }

  .app-content-one-sd {
    position: absolute;
    z-index: 3;
    width: 30%;
    height: 100%;
    background: #9eb3ce;
    left: 57%;
    top: 4%;
  }
}

.item-app-general-content-two {
  position: relative;
  width: 100%;
  max-width: 1920px;
  background: #afcbec;
  height: 550px;
  margin: 100px auto 20px;
  //background: #388a98;
  .app-image-two {
    position: relative;
    z-index: 2;
    width: 40%;
    float: right;
    height: 100%;
    background: url("./picture/AppTwo.png") no-repeat center;
    background-size: 700px 700px;
    //margin-top: 1.5%;
    //padding-top: 30px;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }

  .app-content-two {
    float: right;
    position: relative;
    text-align: left;
    //top: -70%;
    left: 5%;
    z-index: 3;
    width: 30%;
    height: 50%;
    //background: #b57676;
  }

  .app-content-two-title {
    font-family: "微软雅黑", serif;
    margin-top: 10%;
    font-size: 32px;
    font-weight: bold;
    color: rgb(101, 115, 134);
  }

  .app-content-two-subtitle {
    font-family: "微软雅黑", serif;
    font-size: 19px;
    font-weight: bold;
    color: #9e9e9e;
  }

  .app-content-two-text {
    margin-top: 20px;
    font-family: "微软雅黑", serif;
    font-size: 17px;
    color: #ffffff;
  }
}

.item-app-general-content-three {
  position: relative;
  display: flex;
  width: 95%;
  max-width: 1920px;
  height: 600px;
  margin: auto;
  padding-bottom: 40px;
  //background: #388a98;
  .app-image-three {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    //min-height: 600px;
    //background: url("./picture/item-app-image-three.jpg") no-repeat center / cover;
    //image-rendering: -moz-crisp-edges;
    //image-rendering: -o-crisp-edges;
    //image-rendering: -webkit-optimize-contrast;
    //image-rendering: crisp-edges;
    //-ms-interpolation-mode: nearest-neighbor;
  }

  .app-content-three {
    position: absolute;
    text-align: center;
    z-index: 4;
    //align-self:center;
    width: 100%;
    height: 80%;
    min-height: 480px;
    margin: 40px auto;
    opacity: 1;
    background: rgba(255, 255, 255, 0.4);
  }

  .app-content-three-title {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    font-family: "微软雅黑", serif;
    font-size: 40px;
    font-weight: bold;
    color: #4b5463;
  }

  .app-content-three-detail {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    margin: auto;
    padding-top: 40px;
    font-family: "微软雅黑";
    font-weight: bold;
    font-size: 25px;
    color: #000000;
  }

  .app-content-three-detail-one {
    width: 250px;
  }

  .app-content-three-detail-two {
    width: 250px;
  }

  .app-content-three-detail-three {
    width: 250px;
  }

  .app-content-three-detail-four {
    width: 250px;
  }

  .app-content-three-detail-slogan {
    padding-top: 10px;
    font-family: "微软雅黑", serif;
    font-weight: bold;
    font-size: 25px;
    color: #808080;
  }

  .app-content-three-detail-text {
    font-family: "微软雅黑", serif;
    font-size: 18px;
    color: #4d6793;
  }
}

// 公众号一站式平台
.item-wechat-general {
  position: relative;
  z-index: 1;
  width: 100%;
  min-width: 1450px;
  height: 780px;
  margin: auto;
  background-image: url("./picture/ServiceWeb.jpg");
  background-attachment: fixed;
  background-size: 100% 100%;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;

  .item-wechat-title-des {
    padding-top: 75px;
    color: white;
    text-align: center;
    font-family: "微软雅黑", serif;
    font-size: 36px;
    font-weight: bold;
    margin: auto;
  }

  .item-wechat-title-des-sub {
    color: #b3b3b3;
    text-align: center;
    font-family: "微软雅黑", serif;
    font-size: 19px;
    font-weight: bold;
    margin: auto;
  }

  .item-wechat-general-content {
    position: relative;
    display: flex;
    text-align: center;
    width: 80%;
    max-width: 1920px;
    height: 80%;
    margin: auto;
    padding: 70px 0 100px;
    //background: #99A9BF;
  }

  .wechat-image-one {
    position: relative;
    z-index: 2;
    width: 50%;
    height: 90%;
    //margin-top: 40px;
    opacity: 1;
    border-radius: 50px 50px 50px 50px;
    background: url("./picture/item-wechat-image.jpg") no-repeat center / cover;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }

  .wechat-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
  }

  .wechat-content-one {
    position: relative;
    text-align: left;
    width: 70%;
    height: 30%;
    margin: 10px 80px;
    //background: #5c8ed0;
  }

  .wechat-content-one-title {
    margin-top: -80px;
    margin-left: 120px;
    font-family: "微软雅黑", serif;
    font-weight: bold;
    font-size: 20px;
    color: #cbcbcb;
  }

  .wechat-content-one-text {
    margin-left: 120px;
    font-family: "微软雅黑", serif;
    font-size: 15px;
    color: #7dcb97;
    height: 80px;
  }

  .wechat-content-two {
    position: relative;
    text-align: left;
    width: 70%;
    height: 30%;
    margin: 25px 80px;
    //background: #093f86;
  }

  .wechat-content-two-title {
    margin-top: -80px;
    margin-left: 120px;
    font-family: "微软雅黑";
    font-weight: bold;
    font-size: 20px;
    color: #cbcbcb;
  }

  .wechat-content-two-text {
    margin-left: 120px;
    font-family: "微软雅黑";
    font-size: 15px;
    color: #7dcb97;
    height: 80px;
  }

  .wechat-content-three {
    position: relative;
    text-align: left;
    width: 70%;
    height: 30%;
    margin: 25px 80px;
    //background: #88a0bf;
  }

  .wechat-content-three-title {
    margin-top: -80px;
    margin-left: 120px;
    font-family: "微软雅黑";
    font-weight: bold;
    font-size: 20px;
    color: #cbcbcb;
  }

  .wechat-content-three-text {
    margin-left: 120px;
    font-family: "微软雅黑";
    font-size: 15px;
    color: #7dcb97;
    height: 80px;
  }
}

.item-machine-general {
  position: relative;
  z-index: 1;
  //display: flex;
  width: 100%;
  min-width: 1450px;
  height: 900px;
  margin: auto;
  background: rgb(255, 255, 255); /* fallback for old browsers */
  .item-machine-general-title {
    position: absolute;
    //margin-left: 30px;
    z-index: 5;
    //left: -10px;
    width: 200px;
    height: 75px;
    text-align: center;
    background: rgba(130, 138, 146, 0.8);
  }

  .ml-content {
    margin: 50px auto 0;
    width: 90%;
    height: 60%;
  }

  .ml-img {
    float: left;
    width: 800px;
    height: 100%;
    //max-width: 60%;
    background: url("./picture/AIService.png") no-repeat center/ cover;
    background-size: 100% 100%;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }

  .ml-text {
    font-family: "微软雅黑", serif;
    font-size: 17px;
    text-align: left;
    float: left;
    margin-left: 5%;
    width: calc(~"80% - 800px");
    max-width: 600px;
    height: 90%;
    //background: #2f527d;
    color: #6d6d6d;
  }

  .ml-text-point {
    font-size: 18px;
    margin-top: 4%;
    margin-bottom: 4%;
    color: #556272;
  }

  .ml-text-points {
    margin-top: 12%;
  }

  .item-machine-general-title-text {
    margin: auto;
    //padding-left: 30px;
    font-family: "微软雅黑", serif;
    font-size: 30px;
    line-height: 75px;
    font-weight: bold;
    color: #e3e3e3;
  }

  .item-machine-general-sum {
    text-align: center;
    font-family: "微软雅黑", serif;
    padding-top: 75px;
    font-size: 36px;
    font-weight: bold;
    color: #556272;
  }

  .item-machine-general-sum-sub {
    color: #999999;
    text-align: center;
    font-family: "微软雅黑", serif;
    font-size: 19px;
    font-weight: bold;
    margin: auto;
  }

  .item-machine-general-content {
    position: relative;
    display: flex;
    text-align: center;
    width: 100%;
    max-width: 1920px;
    height: 100%;
    margin: auto;
    padding: 20px 0;
    //background: #ffffff;
  }
}

.mobile-item-machine-general {
  position: relative;
  z-index: 1;
  //display: flex;
  width: 100vw;
  //min-width: 1400px;
  height: 2000px;
  margin: auto;
  background: rgb(255, 255, 255); /* fallback for old browsers */
  .mobile-item-machine-general-title {
    position: absolute;
    //margin-left: 30px;
    z-index: 5;
    left: 0;
    width: 350px;
    height: 120px;
    text-align: center;
    background: rgba(95, 116, 134, 0.8);
  }

  .mobile-ml-content {
    margin: 50px auto 0;
    width: 90%;
    height: 60%;
  }

  .mobile-ml-img {
    //float: left;
    width: 800px;
    margin: auto;
    height: 80%;
    //max-width: 60%;
    background: url("./picture/AIService.png") no-repeat center/ cover;
    background-size: 100% 100%;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }

  .mobile-ml-text {
    font-family: "微软雅黑", serif;
    font-size: 32px;
    text-align: left;
    width: 80%;
    margin: 60px auto auto;
    //float: left;
    height: 90%;
    //background: #2f527d;
    color: #6d6d6d;
  }

  .mobile-ml-text-point {
    font-size: 32px;
    margin-top: 4%;
    margin-bottom: 4%;
    color: #556272;
  }

  .mobile-ml-text-points {
    margin-top: 12%;
  }

  .mobile-item-machine-general-title-text {
    margin: auto;
    //padding-left: 30px;
    font-family: "微软雅黑", serif;
    font-size: 65px;
    line-height: 120px;
    font-weight: bold;
    color: #e3e3e3;
  }

  .mobile-item-machine-general-sum {
    padding-top: 170px;
    font-family: "微软雅黑", serif;
    font-size: 60px;
    text-align: center;
    font-weight: bold;
    color: rgb(85, 98, 114);
  }

  .mobile-item-machine-general-sum-sub {
    color: #999999;
    text-align: center;
    font-family: "微软雅黑", serif;
    font-size: 40px;
    font-weight: bold;
    margin: auto;
  }
}

.mobile-service-flow {
  width: 100%;
  height: 720px;
  background: #baddfc;
  margin-bottom: 80px;

  .mobile-service-flow-title {
    font-family: "微软雅黑", serif;
    width: 300px;
    margin: auto;
    padding-top: 40px;
    font-size: 60px;
    font-weight: bold;
    text-align: center;
    color: #556272;
  }

  .mobile-service-flow-title-sub {
    font-family: "微软雅黑", serif;
    margin: auto;
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    color: #a3a3a3;
  }

  .mobile-service-flow-show {
    margin-top: 48px;
    width: 100%;
    overflow-x: scroll;
    height: 300px;
    background: white;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
  }

  .mobile-service-flow-show-inside {
    width: 3200px;
  }

  .mobile-service-flow-step {
    line-height: 180px;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    float: left;
    height: 180px;
    background: #e0e0e0;
    width: 300px;
    margin-top: 60px;
    margin-right: 25px;
    margin-left: 25px;
  }

  .mobile-service-flow-sign {
    height: 200px;
    text-align: center;
    line-height: 230px;
    float: left;
    width: 50px;
    margin-top: 50px;
  }
}

.service-flow {
  width: 100%;
  min-width: 1450px;
  height: 500px;
  margin: auto;
  background: #baddfc; /* fallback for old browsers */
  //background: -webkit-linear-gradient(to right, #859398, #283048); /* Chrome 10-25, Safari 5.1-6 */
  //background: linear-gradient(to right, #859398, #283048); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  .service-flow-title {
    font-family: "微软雅黑", serif;
    width: 200px;
    margin: auto;
    padding-top: 40px;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    color: #556272;
  }

  .service-flow-title-sub {
    font-family: "微软雅黑", serif;
    margin: auto;
    font-size: 19px;
    font-weight: bold;
    text-align: center;
    color: #a3a3a3;
  }

  .service-flow-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: auto auto;
    width: 95%;
    height: 70%;
    //background: #5ab5fa;
  }

  .service-flow-step {
    display: flex;
    justify-content: center;
    width: 120px;
    height: 140px;
    background: rgba(255, 255, 255, 0.8);
    margin: auto 10px;
    font-size: 18px;
    color: #000000;
    line-height: 36px;
    align-items: center;
    cursor: pointer;
  }

  .service-flow-step-back {
    display: flex;
    justify-content: center;
    width: 120px;
    height: 140px;
    background: #416086;
    margin: auto 10px;
    font-size: 22px;
    font-weight: bold;
    color: #ffffff;
    line-height: 36px;
    align-items: center;
    box-shadow: 0 0 5px #000000;
    cursor: pointer;
  }
}

//mobile
.mobile-service-page {
  //min-width: 1450px;
  width: 100%;

  .mobile-service-items {
    width: 100%;
    //background: #83a4d4; /* fallback for old browsers */
    //background: -webkit-linear-gradient(to right, #b6fbff, #83a4d4); /* Chrome 10-25, Safari 5.1-6 */
    //background: linear-gradient(to right, #b6fbff, #83a4d4); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }

.mobile-item-web-general {
  width: 100%;
  height: 1350px;
  background-image: url("./picture/ServiceWeb.jpg");
  background-size: auto 1400px;
}

.swiper-container {
  width: 200px;
  height: 100px;
  --swiper-pagination-color: #ffffff;
  .swiper-pagination {
    position: absolute;
    bottom: 40px;
    left: 0;
    width: 100%;
    text-align: center;
    transition: .3s opacity;
    transform: translate3d(0,0,0);
    z-index: 99;
    .swiper-pagination-bullet {
      margin: 0 4px;
      width: 20px !important;
      height: 20px !important;
      display: inline-block;
      border-radius: 100%;
      background: rgba(255, 255, 255, 0.7);
      opacity: .2;
    }
    .swiper-pagination-bullet-active {
      opacity: 1;
      background: var(--swiper-pagination-color,var(--swiper-theme-color));
    }
  }
}

.mobile-web-general-paper {
  height: 3250px;
  width: 100%;
  margin: auto;
  background: white;
}

.mobile-item-web-general-title {
  position: absolute;
  //margin-left: 30px;
  z-index: 5;
  left: 0;
  width: 350px;
  height: 120px;
  text-align: center;
  background: rgba(95, 116, 134, 0.8);
}

.mobile-item-web-general-title-text {
  margin: auto;
  //padding-left: 30px;
  font-family: "微软雅黑", serif;
  font-size: 65px;
  line-height: 120px;
  font-weight: bold;
  color: #e3e3e3;
}

.mobile-item-web-title-des {
  padding-top: 120px;
  font-family: "微软雅黑", serif;
  font-size: 60px;
  text-align: center;
  font-weight: bold;
  color: rgb(255, 255, 255);
}

.mobile-item-web-title-des-sub {
  color: #999999;
  text-align: center;
  font-family: "微软雅黑", serif;
  font-size: 40px;
  font-weight: bold;
  margin: auto;
}

.mobile-item-web-general-list {
  display: flex;
  flex-direction: column;
  //justify-content:center;
  //align-items:center;
  text-align: left;
  margin: 6% 0 0;
  float: left;
  width: 100%;
  height: 70%;
  //border-radius: 100px;
  //min-height: 800px;
  //background: rgba(165, 165, 165, 0.3);

  .swiper-slide {
    display: table-cell;
    width: 100%;
    height: 88%;
    text-align: center;
    vertical-align: middle;
    //border-radius: 100px;
    //min-height: 800px;
    background: rgba(165, 165, 165, 0.3);
  }
}

.mobile-item-web-general-content {
  position: relative;
  display: flex;
  flex-direction: column;
  //justify-content:center;
  //align-items:center;
  float: left;
  width: 100%;
  height: 100%;
  //margin-left: 0;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 40px;
  text-align: center;
}

.mobile-content-title {
  display: flex-cell;
  font-size: 50px;
  font-weight: bold;
  color: #ffffff;
}

.mobile-content-point {
  display: flex-cell;
  text-align: left;
  padding-top: 20px;
  font-size: 35px;
  font-weight: bold;
  color: #cbcbcb;
}

.mobile-content-point-text {
  display: flex-cell;
  text-align: left;
  padding-top: 5px;
  padding-left: 70px;
  font-size: 32px;
  color: #8fd6dc;
}

  // 图片
  // 策略定位
  .strategy-image {
    z-index: auto;
    position: relative;
    float: left;
    width: 55%;
    height: 80%;
    min-height: 600px;
    margin-left: 0;
    background: url("./picture/strategy-image.jpg") no-repeat center / cover;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }

  // 视觉设计
  .visual-image {
    z-index: auto;
    position: relative;
    float: left;
    width: 55%;
    height: 80%;
    min-height: 600px;
    margin-left: 0;
    background: url("./picture/visual-image.jpg") no-repeat center / cover;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }

  // 系统开发
  .system-image {
    z-index: auto;
    position: relative;
    float: left;
    width: 55%;
    height: 80%;
    min-height: 600px;
    margin-left: 0;
    background: url("./picture/system-image.jpg") no-repeat center / cover;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }

  // 后台管理
  .backstage-image {
    z-index: auto;
    position: relative;
    float: left;
    width: 55%;
    height: 80%;
    min-height: 600px;
    margin-left: 0;
    background: url("./picture/backstage-image.jpg") no-repeat center / cover;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }

  // 算法实现
  .algorithm-image {
    z-index: auto;
    position: relative;
    float: left;
    width: 55%;
    height: 80%;
    min-height: 600px;
    margin-left: 0;
    background: url("./picture/algorithm-image.jpg") no-repeat center / cover;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }

  .mobile-item-app-general {
    width: 100%;

    .mobile-service-items-app-title {
      width: 20%;
      text-align: center;
      margin: auto;
      padding-top: 2%;
      font-size: 36px;
      font-weight: bold;
      border-bottom: 4px solid #ffffff;
    }

    .mobile-item-app-general-content-one {
      width: 100%;
      height: 1200px;
      padding-top: 100px;
      //background: #9d7272;
      .mobile-app-image-one {
        position: relative;
        z-index: 2;
        width: 100%;
        height: 50%;
        background: url("./picture/AppOne.png") no-repeat center;
        background-size: 700px 600px;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
      }

      .mobile-app-content-one-sd {
        position: relative;
        //z-index: 3;
        width: 82%;
        margin: auto;
        height: 45%;
        background: #9eb3ce;
        top: 8%;
      }

      .mobile-app-content-one {
        //float: left;
        position: relative;
        //top: -70%;
        //left: 65%;
        z-index: 3;
        width: 78%;
        height: 45%;
        top: -38%;
        left: 108px;
        background: rgba(175, 203, 236, 0.9);
        //background: white;
      }

      .mobile-app-content-one-title {
        margin-left: 40px;
        font-family: "微软雅黑", serif;
        margin-top: 30px;
        padding-top: 30px;
        font-size: 45px;
        font-weight: bold;
        color: rgb(101, 115, 134);
      }

      .mobile-app-content-one-subtitle {
        margin-left: 40px;
        font-family: "微软雅黑", serif;
        font-size: 40px;
        font-weight: bold;
        color: #9e9e9e;
      }

      .mobile-app-content-one-text {
        margin-left: 40px;
        margin-top: 25px;
        font-family: "微软雅黑", serif;
        font-size: 32px;
        color: rgb(255, 255, 255);
      }
    }


    .mobile-item-app-general-content-two {
      position: relative;
      width: 100%;
      max-width: 1920px;
      background: #b2ccec;
      height: 700px;
      margin: 200px auto 20px;
      //background: #388a98;
      .mobile-app-image-two {
        margin-top: 10px;
        position: relative;
        z-index: 2;
        width: 40%;
        float: right;
        height: 100%;
        background: url("./picture/AppTwo.png") no-repeat center;
        background-size: 780px 780px;
        //margin-top: 1.5%;
        //padding-top: 30px;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
      }

      .mobile-app-content-two {
        float: right;
        position: relative;
        text-align: left;
        //top: -70%;
        left: 2%;
        z-index: 3;
        width: 50%;
        height: 50%;
        //background: #b57676;
      }

      .mobile-app-content-two-title {
        font-family: "微软雅黑", serif;
        margin-top: 20%;
        font-size: 45px;
        font-weight: bold;
        color: rgb(101, 115, 134);
      }

      .mobile-app-content-two-subtitle {
        font-family: "微软雅黑", serif;
        font-size: 40px;
        font-weight: bold;
        color: #9e9e9e;
      }

      .mobile-app-content-two-text {
        margin-top: 20px;
        font-family: "微软雅黑", serif;
        font-size: 32px;
        color: #ffffff;
      }
    }

    .mobile-item-app-general-content-three {
      position: relative;
      display: flex;
      width: 100%;
      max-width: 1920px;
      height: 600px;
      margin: auto;
      padding-bottom: 40px;
      //background: #388a98;
      .mobile-app-image-three {
        position: relative;
        z-index: 2;
        width: 100%;
        height: 100%;
      }

      .mobile-app-content-three {
        position: absolute;
        text-align: center;
        z-index: 4;
        //align-self:center;
        width: 100%;
        height: 80%;
        min-height: 480px;
        margin: 40px auto;
        opacity: 1;
        background: rgba(255, 255, 255, 0.4);
      }

      .mobile-app-content-three-title {
        padding-top: 30px;
        padding-bottom: 10px;
        text-align: center;
        font-family: "微软雅黑", serif;
        font-size: 55px;
        font-weight: bold;
        color: #4b5463;
      }

      .mobile-app-content-three-detail {
        position: relative;
        display: flex;
        justify-content: space-evenly;
        margin: auto;
        padding-top: 80px;
        font-family: "微软雅黑", serif;
        font-weight: bold;
        font-size: 25px;
        color: #000000;
      }

      .mobile-app-content-three-detail-one {
        width: 200px;
      }

      .mobile-app-content-three-detail-two {
        width: 200px;
      }

      .mobile-app-content-three-detail-three {
        width: 200px;
      }

      .mobile-app-content-three-detail-four {
        width: 200px;
      }

      .mobile-app-content-three-detail-slogan {
        padding-top: 10px;
        font-family: "微软雅黑", serif;
        font-weight: bold;
        font-size: 38px;
        color: #808080;
      }

      .mobile-app-content-three-detail-text {
        padding-top: 20px;
        font-family: "微软雅黑", serif;
        font-size: 30px;
        color: #4d6793;
      }
    }
  }

  .mobile-item-wechat-general {
    background-image: url("./picture/ServiceWeb.jpg");
    background-size: auto 1300px;
    width: 100%;
    height: 1300px;

    .mobile-service-items-wechat-title {
      position: absolute;
      //margin-left: 30px;
      z-index: 5;
      left: 0;
      width: 350px;
      height: 120px;
      text-align: center;
      background: rgba(95, 116, 134, 0.8);
    }

    .mobile-item-wechat-title-des {
      padding-top: 170px;
      font-family: "微软雅黑", serif;
      font-size: 60px;
      text-align: center;
      font-weight: bold;
      color: rgb(241, 241, 241);
    }

    .mobile-item-wechat-title-des-sub {
      color: #999999;
      text-align: center;
      font-family: "微软雅黑", serif;
      font-size: 40px;
      font-weight: bold;
      margin: auto;
    }

    .mobile-service-items-wechat-title-text {
      margin: auto;
      //padding-left: 30px;
      font-family: "微软雅黑", serif;
      font-size: 65px;
      line-height: 120px;
      font-weight: bold;
      color: #e3e3e3;
    }

    .mobile-item-wechat-general-content {
      display: flex;
      text-align: center;
      width: 100%;
      height: 650px;
      padding-top: 40px;
    }
    //
    //.mobile-wechat-image-one {
    //  position: relative;
    //  z-index: 2;
    //  width: 50%;
    //  height: 600px;
    //  background: url("./picture/item-wechat-image.jpg") no-repeat center / cover;
    //  image-rendering: -moz-crisp-edges;
    //  image-rendering: -o-crisp-edges;
    //  image-rendering: -webkit-optimize-contrast;
    //  image-rendering: crisp-edges;
    //  -ms-interpolation-mode: nearest-neighbor;
    //}

    .mobile-wechat-content {
      position: relative;
      display: flex;
      flex-direction: column;
      margin: auto;
      width: 90%;
      height: 600px;
    }

    .mobile-wechat-content-one {
      position: relative;
      text-align: left;
      width: 85%;
      height: 30%;
      margin: 50px 50px;
      //background: #5c8ed0;
    }

    .mobile-wechat-content-one-title {
      margin-top: -170px;
      margin-left: 200px;
      font-weight: bold;
      font-size: 35px;
      color: rgb(203, 203, 203);
    }

    .mobile-wechat-content-one-text {
      margin-left: 200px;
      font-size: 32px;
      color: rgb(125, 203, 151);
    }

    .mobile-wechat-content-two {
      position: relative;
      text-align: left;
      width: 85%;
      height: 30%;
      margin: 50px 50px;
      //background: #093f86;
    }

    .mobile-wechat-content-two-title {
      margin-top: -170px;
      margin-left: 200px;
      font-weight: bold;
      font-size: 35px;
      color: rgb(203, 203, 203);
    }

    .mobile-wechat-content-two-text {
      margin-left: 200px;
      font-size: 32px;
      color: rgb(125, 203, 151);
    }

    .mobile-wechat-content-three {
      position: relative;
      text-align: left;
      width: 85%;
      height: 30%;
      margin: 50px 50px;
      //background: #88a0bf;
    }

    .mobile-wechat-content-three-title {
      margin-top: -170px;
      margin-left: 200px;
      font-weight: bold;
      font-size: 35px;
      color: rgb(203, 203, 203);
    }

    .mobile-wechat-content-three-text {
      margin-left: 200px;
      font-size: 32px;
      color: rgb(125, 203, 151);
    }
  }

  .mobile-item-machine-general {
    width: 100%;

    .mobile-service-items-machine-title {
      width: 20%;
      text-align: center;
      margin: auto;
      padding-top: 2%;
      font-size: 36px;
      font-weight: bold;
      border-bottom: 4px solid #ffffff;
    }

    .mobile-item-machine-general-content {
      position: relative;
      display: flex;
      text-align: center;
      padding-top: 40px;
      width: 100%;
      height: 1200px;
      //background: #ffffff;
    }
  }
}


.mobile-app-general-paper {
  height: 3250px;
  width: 100%;
  margin: auto;
  background: white;
}

.mobile-item-app-general-title-text {
  margin: auto;
  //padding-left: 30px;
  font-family: "微软雅黑", serif;
  font-size: 65px;
  line-height: 120px;
  font-weight: bold;
  color: #e3e3e3;
}

.mobile-item-app-general-title {
  position: absolute;
  //margin-left: 30px;
  z-index: 5;
  left: 0;
  width: 350px;
  height: 120px;
  text-align: center;
  background: rgba(95, 116, 134, 0.8);
}

.mobile-item-app-title-des {
  padding-top: 170px;
  font-family: "微软雅黑", serif;
  font-size: 60px;
  text-align: center;
  font-weight: bold;
  color: rgb(85, 98, 114);
}

.mobile-item-app-title-des-sub {
  color: #999999;
  text-align: center;
  font-family: "微软雅黑", serif;
  font-size: 40px;
  font-weight: bold;
  margin: auto;
}