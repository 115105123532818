.info-page {
    min-width: 1150px;
    background: #e3e3e3;
}

.info-comb-wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin: 0 auto;
    padding: 100px 0 40px;
    width: 1120px;
    height: auto;
}

.info-comb-left {
    padding: 20px;
    width: 800px;
    height: auto;
    background-color: white;
    border: 1px solid #eee;
    box-sizing: border-box;
}

.info-comb-right {
    margin-left: 20px;
    width: 300px;
    height: 400px;
    background-color: white;
    border: 1px solid #eee;
}

.info-title {
    /*margin: 20px;*/
    padding-bottom: 10px;
    overflow: hidden;
    border-bottom: 1px solid #e4e3e3;
}

.info-title-text {
    position: relative;
    float: left;
    color: #057;
    font-size: 16px;
    font-weight: normal;
    text-align: center;
}

.info-title-text:after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 2px;
    background: #057;
}

.info-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
}

.info-card {
    display: flex;
    flex-direction: row;
    padding: 20px 0;
    width: 100%;
    height: auto;
    border-bottom: 1px dashed #d3d3d3;
    overflow: hidden;
}

.info-card-left {
    width: 200px;
    height: 120px;
    overflow: hidden;
}

.info-card-cover {
    width: 100%;
    height: 100%;
    display: inline-block;
    transition: .5s;
    cursor: pointer;
}

.info-card-cover:hover {
    transform: scale(1.3);
    overflow: hidden;
    cursor: pointer;
}

.info-card-right {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    width: calc(100% - 220px);
    height: auto;
}

.info-card-right h2 a {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
    color: black;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.info-card-right h2 a:hover {
    color: #5ac2fa;
    cursor: pointer;
}

.info-card-right p {
    margin-top: 10px;
    height: 44px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    font-size: 14px;
    line-height: 22px;
    color: #a5a5a5;
}

.info-more {
    color: #5ac2fa;
    cursor: pointer;
}

.top-info {
    padding: 20px;
    width: 100%;
    height: auto;
    background: #fff;
    border: 1px solid #eee;
}

.side-title {
    font-size: 16px;
    border-bottom: 1px solid #eee;
    color: #484848;
    font-weight: normal;
    position: relative;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.side-title:after {
    content: "";
    position: absolute;
    width: 120px;
    height: 2px;
    background: #000;
    left: 0;
    bottom: -1px;
    -moz-transition: all .5s ease;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
}

.side-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
}

.top-info-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    height: auto;
}

.top-info-card i {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    background: #B1B1B1;
    font-size: 12px;
    color: white;
}

.top-info-card:first-child i,
.top-info-card:nth-child(2) i,
.top-info-card:nth-child(3) i {
    background: #FF6600;
}

.top-info-card div {
    margin-left: 5px;
    width: calc(100% - 21px);
    height: 30px;
    font-size: 14px;
    line-height: 30px;
    color: #000;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
}

.top-info-card div:hover {
    color: #5ac2fa;
    cursor: pointer;
}

.rec-info-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    height: auto;
}

.rec-info-card li {
    position: relative;
    margin-bottom: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
    line-height: 22px;
    color: #000;
    cursor: pointer;
}

/*.rec-info-card li div {*/
/*    font-size: 14px;*/
/*    line-height: 22px;*/
/*    color: #000;*/
/*}*/

.rec-info-card li:hover {
    color: #5ac2fa;
    cursor: pointer;
}