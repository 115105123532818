/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.nav-phone-icon {
  display: none;
  position: absolute;
  right: 30px;
  top: 32px;
  z-index: 1;
  width: 16px;
  height: 22px;
  cursor: pointer;
}
@media only screen and (min-width: 0) and (max-width: 1280px) {
  .en-us #search-box {
    display: none;
  }
}
@media only screen and (min-width: 0) and (max-width: 1180px) {
  .zh-cn #search-box {
    display: none;
  }
}
@media only screen and (min-width: 0) and (max-width: 992px) {
  #search-box {
    display: none;
  }
  .code-boxes-col-2-1,
  .code-boxes-col-1-1 {
    float: none;
    width: 100%;
  }
  .preview-image-boxes {
    margin: 0 !important;
    float: none;
    width: 100%;
  }
  .preview-image-box {
    padding-left: 0;
    margin: 10px 0;
  }
  a#logo {
    width: 150px;
    margin-left: auto;
    margin-right: auto;
    float: none;
    display: block;
  }
  .banner-entry {
    position: relative;
    top: 30px;
    left: 0;
    text-align: center;
  }
  .banner-wrapper {
    background-position: 40%;
  }
  .content-wrapper .text-wrapper {
    float: none;
    text-align: center;
    left: 0;
    width: 100%;
    padding: 0;
  }
  .content-wrapper .text-wrapper > p {
    max-width: 100% !important;
    padding: 0 40px;
  }
  .content-wrapper.page {
    min-height: 800px;
    height: 800px;
  }
  button.lang {
    display: block;
    margin: 29px auto 16px;
    color: rgba(0, 0, 0, 0.85);
    border-color: rgba(0, 0, 0, 0.85);
  }
  div.version {
    display: block;
    margin: 29px auto 16px;
  }
  div.version > .ant-select-selection {
    color: rgba(0, 0, 0, 0.85);
  }
  div.version > .ant-select-selection:not(:hover) {
    border-color: rgba(0, 0, 0, 0.85);
  }
  .popover-menu {
    width: 300px;
  }
  .popover-menu button.lang {
    margin: 16px auto;
    float: none;
  }
  .popover-menu div.version {
    margin: 32px auto 16px;
    float: none;
  }
  .popover-menu .ant-popover-inner {
    overflow: hidden;
  }
  .popover-menu .ant-popover-inner-content {
    padding: 0;
  }
  ul#nav,
  ul#nav li {
    width: 100%;
    font-size: 14px;
  }
  ul#nav li {
    line-height: 60px;
    height: 60px;
    padding: 0 !important;
    border: 0;
    color: #333;
  }
  ul#nav li .header-link {
    color: #333;
  }
  .toc {
    display: none;
  }
  .nav-phone-icon {
    display: block;
  }
  .nav-phone-icon:before {
    content: "";
    display: block;
    border-radius: 2px;
    width: 16px;
    height: 2px;
    background: #777;
    box-shadow: 0 6px 0 0 #777, 0 12px 0 0 #777;
    position: absolute;
  }
  .main {
    height: calc(14%);
  }
  .aside-container {
    float: none;
    width: auto;
    padding-bottom: 30px;
    border-bottom: 1px solid #e9e9e9;
    border-right: 0;
    margin-bottom: 30px;
  }
  .main-container {
    padding-left: 16px;
    padding-right: 16px;
    margin-right: 0;
  }
  .main-container > .markdown > * {
    width: 100% !important;
  }
  .main-wrapper {
    width: 100%;
    border-radius: 0;
    margin: 0;
  }
  footer {
    text-align: center;
  }
  footer ul li {
    float: none;
    width: auto;
  }
  footer ul li > h2 .anticon {
    display: none;
  }
}
